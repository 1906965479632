import {noop} from '@renofi/utilities';

import useMutation from '../../useMutation';
import {SUBMIT_WEB_REVIEWS_SCORE} from '../../../mutations';

export default function useSubmitWebReviewsScore({
  onCompleted = noop,
  refetchQueries = [],
} = {}) {
  const [submitWebReviewsScore, {data, loading, error}] = useMutation(
    SUBMIT_WEB_REVIEWS_SCORE,
    {
      onCompleted,
      refetchQueries,
    },
  );
  const response = data?.submitWebReviewsScore?.gcddReview;

  return {submitWebReviewsScore, response, loading, error};
}
