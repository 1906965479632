import React from 'react';

import PropTypes from 'prop-types';

import {noop} from '@renofi/utilities';

import {Cover} from '../Modal/styled';

import {Z_INDEX, Content, Wrapper} from './styled';

const Drawer = ({
  animate = false,
  backdrop = false,
  whiteBg = false,
  contentCss = {},
  min,
  max,
  onClickBackdrop = noop,
  open,
  position = 'left',
  fixed,
  sticky,
  children,
  ...props
}) => (
  <>
    {Boolean(open || props?.show) && backdrop ? (
      <Cover visible onClick={onClickBackdrop} zIndex={Z_INDEX - 1} />
    ) : null}
    <Wrapper
      whiteBg={whiteBg}
      animate={animate}
      fixed={fixed}
      position={position}
      sticky={sticky}
      width={open ? max : min}
      {...props}>
      <Content fixed={fixed} sticky={sticky} css={contentCss} width={max}>
        {children}
      </Content>
    </Wrapper>
  </>
);

Drawer.propTypes = {
  animate: PropTypes.bool,
  whiteBg: PropTypes.bool,
  children: PropTypes.node,
  contentCss: PropTypes.object,
  open: PropTypes.bool,
  fixed: PropTypes.bool,
  position: PropTypes.oneOf(['left', 'right']),
  sticky: PropTypes.bool,
  min: PropTypes.number,
  max: PropTypes.number,
};

export default Drawer;
