import React, {useContext} from 'react';

import {last, keys} from 'ramda';
import TASK_STATUSES from 'lib/task/taskStatuses';
import {getBadgeVariantByStatus} from 'lib/appraisal';
import {useViewerNavigation} from 'modules/viewer-v2/hooks';

import humanizeSnakeCase from '@renofi/utilities/src/humanizeSnakeCase';
import {useAppraisalOrders} from '@renofi/api';
import {useProject} from '@renofi/graphql';

import Tab from '../Tab';
import {getVariantStatusForTasks} from '../../utils';
import {NON_DOCUMENT_TASK_TYPES} from '../../../../constants';
import ViewerV2Context from '../../../../context';
import {
  getMostRecentReport,
  getTabTitleDetails,
  isTaskReviewed,
} from '../../../../utils';
import Group from '../Group/Group';

const VALID_TASK_TYPES = keys(NON_DOCUMENT_TASK_TYPES);

const AppraisalGroup = () => {
  const {onGroupPathsUpdate} = useContext(ViewerV2Context);
  const {params} = useViewerNavigation();
  const {projectId, itemId} = params;

  const {project, loading: loadingProject} = useProject({
    variables: {id: projectId},
  });
  const {appraisalOrders, ...rest} = useAppraisalOrders({
    variables: {projectId},
  });
  const loading = loadingProject || rest?.loading;

  const currentAppraisalOrder = last(appraisalOrders ?? []);
  const appraisalReport = getMostRecentReport(
    currentAppraisalOrder?.appraisalReportDocuments,
  );
  const appraisalDocs =
    project?.documents?.filter(
      (doc) =>
        doc.documentType === 'appraisal' ||
        doc.documentType === 'completion_certificate',
    ) || [];

  const allTasks = project?.tasks || [];
  const data = allTasks.filter(
    (t) => t.facet === 'appraisal' && VALID_TASK_TYPES.includes(t.taskType),
  );
  const [facetVariant, facetStatus] = currentAppraisalOrder
    ? [
        getBadgeVariantByStatus(currentAppraisalOrder),
        humanizeSnakeCase(currentAppraisalOrder.status),
      ]
    : getVariantStatusForTasks(data);

  if (appraisalReport) {
    data.unshift({
      details: 'Received',
      id: currentAppraisalOrder.id,
      isReport: true,
      path: `/viewer-v2/${projectId}/appraisal_report/${currentAppraisalOrder.id}?documentId=${appraisalDocs[0]?.id}`,
      title: 'Appraisal',
    });
  }

  if (loading || (!data.length && !appraisalReport)) {
    return null;
  }

  return (
    <Group
      onUpdate={(data) =>
        onGroupPathsUpdate('appraisal', 'appraisal_report', data)
      }
      status={facetStatus}
      statusVariant={facetVariant}
      title="Appraisal">
      {data.map((item) => {
        const {isReport} = item;
        if (isReport) {
          return (
            <Tab
              active={currentAppraisalOrder.id === itemId}
              details="Received"
              key={item.id}
              title="Appraisals"
              status="completed"
              path={`/viewer-v2/${projectId}/appraisal_report/${currentAppraisalOrder.id}?documentId=${appraisalDocs[0]?.id}`}
            />
          );
        }
        const taskStatus = isTaskReviewed(item, project)
          ? TASK_STATUSES.COMPLETED
          : item.status;
        const [title, details] = getTabTitleDetails({...item, type: 'task'});

        return (
          <Tab
            active={item.id === itemId}
            details={details}
            title={title}
            status={taskStatus}
            key={item.id}
            path={`/viewer-v2/${projectId}/task/${item.id}`}
          />
        );
      })}
    </Group>
  );
};

export default AppraisalGroup;
