import {noop} from '@renofi/utilities';

import useMutation from '../../useMutation';
import {UPDATE_INSURANCE} from '../../../mutations';

export default function useUpdateInsurance({
  onCompleted = noop,
  refetchQueries = [],
} = {}) {
  const [updateInsurance, {abort, data, loading, error}] = useMutation(
    UPDATE_INSURANCE,
    {onCompleted, refetchQueries},
  );
  const insurance = data?.updateInsurance?.insurance;

  return {abort, updateInsurance, insurance, loading, error};
}
