import React, {useEffect, useState} from 'react';

import PropTypes from 'prop-types';

import {noop} from '@renofi/utilities';

const Timer = ({onFinish = noop, prefix, suffix, start, value}) => {
  const [timer, setTimer] = useState(value);
  useEffect(() => {
    const interval = setInterval(() => {
      const diff = start.getTime() + value * 1000 - Date.now();
      if (diff > 0) {
        setTimer(diff);
      } else {
        setTimer(0);
        clearInterval(interval);
        onFinish();
      }
    }, 100);
    return () => {
      try {
        clearInterval(interval);
      } catch {
        // ignore
      }
    };
  }, []);
  return (
    <>
      {[prefix, timer > 0 ? (timer / 1000).toFixed(0) : '0', suffix]
        .filter((part) => !!part)
        .join('')}
    </>
  );
};

Timer.propTypes = {
  onFinish: PropTypes.func,
  prefix: PropTypes.string,
  suffix: PropTypes.string,
  start: PropTypes.any.isRequired,
  value: PropTypes.number.isRequired,
};

export default Timer;
