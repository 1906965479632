import {pathOr} from 'ramda';

import {noop} from '@renofi/utilities';

import useMutation from '../useMutation';
import {SUBMIT_DOCUMENT} from '../../mutations';

export default function useSubmitDocument({
  onCompleted = noop,
  refetchQueries = [],
} = {}) {
  const [submitDocument, {data, loading, error}] = useMutation(
    SUBMIT_DOCUMENT,
    {
      onCompleted,
      refetchQueries,
    },
  );
  const response = pathOr(null, ['submitDocument'], data);
  return {submitDocument, response, loading, error};
}
