import React from 'react';

import PropTypes from 'prop-types';
import {ModalsContext} from 'modules/modals';

import {Box, Tooltip} from '@renofi/components-internal';
import {GET_APPRAISAL_ORDERS, useAcceptReport} from '@renofi/api';
import {
  useMarkAppraisalReviewAsFinished,
  GET_PROJECT_TASKS,
  GET_PROJECT_TIMELINE,
  useCreateNote,
} from '@renofi/graphql';
import {sendEvent} from '@renofi/analytics';
import {useNotifications} from '@renofi/utilities';

import {Approve} from '../../icons';
import MarkReviewAsCompletedModal from '../../../project/components/AppraisalTasks/components/AppraisalOrder/components/MarkReviewAsCompletedModal';

function AppraisalReportDocumentActions({document, projectId}) {
  const {loading: isAcceptingReport} = useAcceptReport();
  const {addNotification} = useNotifications();
  const {createNote} = useCreateNote({});

  const {markAppraisalReviewAsFinished} = useMarkAppraisalReviewAsFinished({
    refetchQueries: [
      GET_PROJECT_TASKS,
      GET_PROJECT_TIMELINE,
      GET_APPRAISAL_ORDERS,
    ],
  });
  const isAccepted = document?.status === 'accepted';
  const disabled = isAccepted || isAcceptingReport;
  const isCompletionCertificate =
    document?.documentType === 'completion_certificate';

  async function onSubmit() {
    if (disabled) return;

    sendEvent('Blueprint/Apprisal/MarkReviewAsCompleted');
    return markAppraisalReviewAsFinished({
      variables: {
        id: projectId,
        documentId: document?.id,
      },
    });
  }

  function tooltipContent() {
    if (isAccepted) return 'Already accepted';
    if (!isAccepted) return 'Accept and mark review as completed';
    if (disabled) return null;
  }

  return (
    <ModalsContext.Consumer>
      {({onClose, setLoading, setModalProps}) => {
        const modalProps = {
          acceptLabel: 'Mark review as completed',
          component: MarkReviewAsCompletedModal,
          componentProps: {
            projectId,
          },
          height: 600,
          hasButtons: true,
          header: 'RenoFi review completed',
          onAccept: async ({body}) => {
            setLoading(true);
            try {
              const {errors} = await createNote({
                variables: {
                  projectId,
                  body,
                  title: `${isCompletionCertificate ? 'COC' : 'Appraisal'} review complete`,
                },
              });

              if (errors) {
                throw new Error('Failed to create a note');
              }

              await onSubmit();

              onClose();

              addNotification({
                variant: 'success',
                content: 'Appraisal review completed',
                type: 'snackbar',
              });
            } catch (err) {
              addNotification({
                variant: 'danger',
                content: 'Failed to complete appraisal review',
                type: 'snackbar',
              });
            } finally {
              setLoading(false);
            }
          },
          onReject: () => onClose(),
        };

        return (
          <Box height={24} ml={16}>
            <Tooltip tag="div" place="top" content={tooltipContent()}>
              <Approve
                active={isAccepted}
                disabled={isAccepted || isAcceptingReport}
                loading={isAcceptingReport}
                onClick={() => {
                  if (disabled) return;
                  setModalProps(modalProps);
                }}
              />
            </Tooltip>
          </Box>
        );
      }}
    </ModalsContext.Consumer>
  );
}

AppraisalReportDocumentActions.propTypes = {
  document: PropTypes.object,
  projectId: PropTypes.string,
};

export default AppraisalReportDocumentActions;
