import {propOr} from 'ramda';

import {noop} from '@renofi/utilities';

import {UNASSIGN_PERMISSIONS} from '../../mutations';
import useMutation from '../useMutation';

export default function useUnassignPermissions({onCompleted = noop} = {}) {
  const [unassignPermissions, {data, loading, error}] = useMutation(
    UNASSIGN_PERMISSIONS,
    {
      onCompleted,
    },
  );

  const response = propOr(null, 'unassignPermissions', data);
  return {unassignPermissions, response, loading, error};
}
