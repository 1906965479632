import {pathOr} from 'ramda';

import {noop} from '@renofi/utilities';

import useMutation from '../../useMutation';
import {SUBMIT_WEB_REVIEW} from '../../../mutations';

export default function useSubmitWebReview({
  onCompleted = noop,
  refetchQueries = [],
} = {}) {
  const [submitWebReview, {data, loading, error}] = useMutation(
    SUBMIT_WEB_REVIEW,
    {
      onCompleted,
      refetchQueries,
    },
  );
  const response = pathOr(null, ['submitWebReview', 'webReview'], data);

  return {submitWebReview, response, loading, error};
}
