import {noop} from '@renofi/utilities';

import useMutation from '../../useMutation';
import {UPDATE_LICENSE} from '../../../mutations';

export default function useUpdateLicense({
  onCompleted = noop,
  refetchQueries = [],
} = {}) {
  const [updateLicense, {abort, data, loading, error}] = useMutation(
    UPDATE_LICENSE,
    {
      onCompleted,
      refetchQueries,
    },
  );
  const license = data?.updateLicense?.license;

  return {abort, updateLicense, license, loading, error};
}
