import {pathOr} from 'ramda';

import {noop} from '@renofi/utilities';

import useMutation from '../useMutation';
import {SUBMIT_SOFT_CREDIT_LIABILITY_OVERRIDE} from '../../mutations';

export default function useSubmitSoftCreditLiabilityOverride({
  onCompleted = noop,
  refetchQueries = [],
} = {}) {
  const [submitSoftCreditLiabilityOverride, {data, loading, error}] =
    useMutation(SUBMIT_SOFT_CREDIT_LIABILITY_OVERRIDE, {
      onCompleted,
      refetchQueries,
    });
  const response = pathOr(null, ['submitSoftCreditLiabilityOverride'], data);

  return {submitSoftCreditLiabilityOverride, response, loading, error};
}
