import {useState} from 'react';

import {propEq, pathOr, reject, isNil, isEmpty} from 'ramda';
import {isFunction} from 'lodash';

import {useIsFilePasswordValid, useSignedUrls} from '@renofi/api';

import useUploadFiles from './useUploadFiles';
import {useSubmitDocument, useSubmitProjectDocument} from './renovation';

const useUploadDocuments = ({basePath = 'documents', projectId}) => {
  const [submitted, setSubmitted] = useState([]);
  const {uploadFiles, uploading} = useUploadFiles({basePath});
  const documents = submitted;
  const {submitDocument} = useSubmitDocument();
  const {submitProjectDocument} = useSubmitProjectDocument();
  const {fetch: getSignedUrls} = useSignedUrls({lazy: true});
  const {fetch: checkPassword} = useIsFilePasswordValid({lazy: true});

  const isPasswordProtected = async (objectName) => {
    const rsp = await checkPassword({
      variables: {filePassword: '', objectName},
    });

    return !pathOr(false, ['data', 'isFilePasswordValid'], rsp);
  };

  const submitUploadedDocuments = async ({
    customMutation,
    customResponsePath,
    customMutationParams,
    documentType,
    shouldReturnLatestFiles = false,
    taskId,
    uploadedFiles = [],
  }) => {
    const submitFunc = customMutation
      ? customMutation
      : documentType
        ? submitProjectDocument
        : submitDocument;
    setSubmitted(submitted.concat(uploadedFiles));

    const submittedFiles = await Promise.all(
      uploadedFiles.map(async (uploadedFile) => {
        const {
          checksum,
          fileName,
          filePassword,
          objectName,
          id: documentId,
          ...args
        } = uploadedFile;
        let passwordProtectedFile;
        if (Boolean(args?.isEncrypted)) {
          passwordProtectedFile = await isPasswordProtected(objectName);
        }

        return submitFunc({
          variables: {
            projectId,
            taskId,
            checksum,
            documentType,
            documentId,
            fileName,
            filePassword,
            objectName,
            passwordProtectedFile,
            ...(customMutationParams
              ? isFunction(customMutationParams)
                ? customMutationParams(uploadedFile)
                : customMutationParams
              : {}),
          },
        });
      }),
    );

    const path =
      customResponsePath ||
      (documentType ? 'submitProjectDocument' : 'submitDocument');
    const completedFiles = reject(isNil, submittedFiles);
    setSubmitted(documents.concat(completedFiles));
    const objectNames = completedFiles.map((completedFile) =>
      pathOr('', ['data', path, 'document', 'objectName'], completedFile),
    );
    const response = !isEmpty(completedFiles)
      ? await getSignedUrls({variables: {objectNames}})
      : [];

    const finalFiles = completedFiles.map((file) => {
      const {id, objectName, ...rest} = pathOr(
        null,
        ['data', path, 'document'],
        file,
      );
      const signedUrls = pathOr([], ['data', 'signedUrls'], response);
      const {url} = signedUrls.find(propEq('objectName', objectName));
      return {
        ...rest,
        id,
        itemId: taskId,
        objectName,
        fileUrl: url,
      };
    });

    const newSubmitted = documents.concat(finalFiles);
    setSubmitted(newSubmitted);

    return shouldReturnLatestFiles ? finalFiles : newSubmitted;
  };

  const uploadDocuments = async ({
    documentType,
    files,
    taskId,
    shouldReturnLatestFiles = false,
    customMutation,
    customResponsePath,
    customMutationParams,
  }) => {
    const uploadedFiles = await uploadFiles(files);

    return submitUploadedDocuments({
      customMutation,
      customResponsePath,
      customMutationParams,
      documentType,
      shouldReturnLatestFiles,
      taskId,
      uploadedFiles,
    });
  };

  return {
    setSubmitted,
    submitted,
    submitUploadedDocuments,
    uploadDocuments,
    uploading,
  };
};

export default useUploadDocuments;
