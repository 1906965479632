import {noop} from '@renofi/utilities';

import {GET_USERS_SEARCH} from '../../queries';
import useToggledQuery from '../useToggledQuery';

export default function useUsersQuickSearch({
  fetchPolicy = 'cache-first',
  lazy = true,
  onCompleted = noop,
  ...options
} = {}) {
  const response = useToggledQuery({
    lazy,
    query: GET_USERS_SEARCH,
    options: {
      onCompleted,
      fetchPolicy,
      ...options,
    },
  });
  const {data, error, loading, fetch} = response;

  return {
    getUsers: fetch,
    users: data?.usersSearch || [],
    data,
    loading,
    error,
  };
}
