import {pathOr} from 'ramda';

import {noop} from '@renofi/utilities';

import {GET_FEASIBILITY_REVIEWS} from '../../queries';
import useMutation from '../useMutation';
import {REVERT_FEASIBILITY_REVIEW_COMPLETION} from '../../mutations';

export default function useRevertFeasibilityReviewCompletion({
  onCompleted = noop,
  refetchQueries = [GET_FEASIBILITY_REVIEWS],
} = {}) {
  const [revertFeasibilityReviewCompletion, {data, loading, error}] =
    useMutation(REVERT_FEASIBILITY_REVIEW_COMPLETION, {
      onCompleted,
      refetchQueries,
    });
  const response = pathOr(null, ['revertFeasibilityReviewCompletion'], data);
  return {revertFeasibilityReviewCompletion, response, loading, error};
}
