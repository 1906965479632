import React from 'react';

import PropTypes from 'prop-types';
import {propOr} from 'ramda';

import SuccessIcon from '@renofi/icons/src/Success';
import CrossCircleIcon from '@renofi/icons/src/CrossCircle';
import WarninIcon from '@renofi/icons/src/Alert';
import {
  dangerLight,
  dangerRegular,
  successRegular,
  white,
} from '@renofi/theme/src/colors';
import {noop} from '@renofi/utilities';

import Flex from '../Flex';
import Box from '../Box';
import Icon from '../Icon';

import {Action, CloseWrapper, Wrapper} from './styled';

const VARIANT_ICONS = {
  danger: CrossCircleIcon,
  success: SuccessIcon,
  warning: WarninIcon,
};

const VARIANT_COLORS = {
  danger: dangerRegular,
  success: successRegular,
  warning: dangerLight,
};

function Snackbar({
  action,
  message,
  width = 680,
  center,
  onClickAction = noop,
  variant = 'success',
  closable,
  onClose,
  ...props
}) {
  const IconComponent = propOr(null, variant, VARIANT_ICONS);
  const variantColor = propOr(white, variant, VARIANT_COLORS);

  return (
    <Wrapper p={3} width={width} center={center} {...props}>
      {IconComponent && (
        <Box mr={3}>
          <IconComponent color={variantColor} width={32} />
        </Box>
      )}
      <Flex width={1} alignItems="center">
        {message}
      </Flex>
      {action && (
        <Box ml={3}>
          <Action onClick={onClickAction}>{action}</Action>
        </Box>
      )}

      {closable && (
        <CloseWrapper onClick={onClose}>
          <Icon color="white" name="cross" />
        </CloseWrapper>
      )}
    </Wrapper>
  );
}

Snackbar.propTypes = {
  action: PropTypes.string,
  message: PropTypes.node.isRequired,
  onClickAction: PropTypes.func,
  onClose: PropTypes.func,
  variant: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  center: PropTypes.bool,
  closable: PropTypes.bool,
};

export default Snackbar;
