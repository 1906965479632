import {sendEvent} from '@renofi/analytics';
import {sessionUrl} from '@renofi/analytics/src/datadog';

import jwtDecode from '../jwtDecode';
import {JWT_KEY, getCookie} from '../cookies';
import {AUTH_TOKEN} from '../storage';
import {getHostname} from '../window';

const hostname = getHostname() || '';
const isLoginApp = hostname.match(/^login.renofi/);

export default ({storage, decode = false}) => {
  const impersonationJwt = storage.getItem(AUTH_TOKEN);
  const jwt = impersonationJwt || getCookie(JWT_KEY);
  if (!jwt && !isLoginApp) {
    sendEvent('JWT/Invalid-or-Missing-JWT', {datadogUrl: sessionUrl()});
    return null;
  }
  return decode ? jwtDecode(jwt) : jwt;
};
