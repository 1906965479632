import {isNil} from 'ramda';

import {noop} from '@renofi/utilities';

import {GET_INTERNAL_USERS_BY_PERMISSION} from '../../queries';
import useLazyQuery from '../useLazyQuery';

export default function useInternalUsersByPermission({
  onCompleted = noop,
} = {}) {
  const [searchInternalUsersByPermission, {data, loading, error}] =
    useLazyQuery(GET_INTERNAL_USERS_BY_PERMISSION, {
      onCompleted,
    });

  const internalUsersByPermission =
    data?.internalUsersByPermission.filter((user) => !isNil(user.firstName)) ||
    [];
  return {
    searchInternalUsersByPermission,
    internalUsersByPermission,
    data,
    loading,
    error,
  };
}
