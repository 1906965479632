import {useMutation} from '@apollo/client';
import {noop} from 'lodash';

import {SUBMIT_APPRAISAL_ORDER_FINAL_INSPECTION} from '../../../mutations';

export default function useSubmitAppraisalOrderFinalInspection({
  onCompleted = noop,
  refetchQueries = [],
} = {}) {
  const [submitAppraisalOrderFinalInspection, {data, loading, error}] =
    useMutation(SUBMIT_APPRAISAL_ORDER_FINAL_INSPECTION, {
      onCompleted,
      refetchQueries,
    });
  const response = data?.submitContractorInformation?.contractor;

  return {submitAppraisalOrderFinalInspection, response, loading, error};
}
