import {propOr} from 'ramda';

import {noop} from '@renofi/utilities';

import useMutation from '../../useMutation';
import {SUBMIT_QUESTIONNAIRE_REFERENCE} from '../../../mutations';

export default function useSubmitQuestionnaireReference({
  onCompleted = noop,
  refetchQueries = [],
} = {}) {
  const [submitQuestionnaireReference, {data, loading, error}] = useMutation(
    SUBMIT_QUESTIONNAIRE_REFERENCE,
    {
      onCompleted,
      refetchQueries,
    },
  );
  const reference = propOr(null, 'submitQuestionnaireReference', data);

  return {submitQuestionnaireReference, reference, loading, error};
}
