import {propOr} from 'ramda';

import {noop} from '@renofi/utilities';

import {BULK_CHANGE_TASK_STATUS} from '../../../mutations';
import useMutation from '../../useMutation';

export default function useBulkChangeTaskStatus({
  onCompleted = noop,
  refetchQueries = [],
  ...options
} = {}) {
  const [bulkChangeTaskStatus, {data, loading, error}] = useMutation(
    BULK_CHANGE_TASK_STATUS,
    {
      onCompleted,
      refetchQueries,
      ...options,
    },
  );

  const response = propOr(null, 'bulkChangeTaskStatus', data);
  return {bulkChangeTaskStatus, response, loading, error};
}
