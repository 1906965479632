import {pathOr} from 'ramda';

import {noop} from '@renofi/utilities';

import {SUBMIT_PAY_STATEMENT_INFORMATION} from '../../../mutations';
import useMutation from '../../useMutation';

export default function useSubmitPayStatementInformation({
  onCompleted = noop,
  refetchQueries = [],
} = {}) {
  const [submitPayStatementInformation, {data, loading, error}] = useMutation(
    SUBMIT_PAY_STATEMENT_INFORMATION,
    {onCompleted, refetchQueries},
  );

  const response = pathOr(
    null,
    ['submitPayStatementInformation', 'payStatementInformation'],
    data,
  );

  return {
    submitPayStatementInformation,
    response,
    loading,
    error,
  };
}
