import {propOr} from 'ramda';

import {useToggledQuery} from '@renofi/graphql';
import {noop} from '@renofi/utilities';

import {GET_PROJECT_FULL_TIMELINE} from '../../queries';

export default function useProjectFullTimeline({
  fetchPolicy = 'cache-first',
  lazy = false,
  onCompleted = noop,
  ...options
} = {}) {
  const response = useToggledQuery({
    lazy,
    query: GET_PROJECT_FULL_TIMELINE,
    options: {
      fetchPolicy,
      onCompleted,
      ...options,
    },
  });
  const {data, error, loading, fetch, refetching} = response;
  const project = propOr(null, 'projectById', data);

  return {fetch, project, data, loading, error, refetching};
}
