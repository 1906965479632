import React from 'react';

import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';

const root = document.getElementsByTagName('body')[0];

class Portal extends React.Component {
  constructor(props) {
    super(props);
    this.element = props.element || document.createElement('div');
  }

  componentDidMount() {
    root.appendChild(this.element);
  }

  componentWillUnmount() {
    root.removeChild(this.element);
  }

  render() {
    return ReactDOM.createPortal(this.props.children, this.element);
  }
}

Portal.propTypes = {
  element: PropTypes.any,
  children: PropTypes.node.isRequired,
};

export default process.env.NODE_ENV === 'test'
  ? () => (Component) => (props) => <Component {...props} />
  : (getElement = () => null) =>
      (Component) =>
      (props) => (
        <Portal element={getElement()}>
          <Component {...props} />
        </Portal>
      );
