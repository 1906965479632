import {propOr} from 'ramda';

import {noop} from '@renofi/utilities';

import useMutation from '../../useMutation';
import {UPDATE_PERSONAL_INFO_AND_RESIDENCY} from '../../../mutations/renovation';

export default function useUpdatePersonalInfoAndResidency({
  onCompleted = noop,
  refetchQueries = [],
} = {}) {
  const [updatePersonalInfoAndResidency, {data, loading, error}] = useMutation(
    UPDATE_PERSONAL_INFO_AND_RESIDENCY,
    {
      onCompleted,
      refetchQueries,
    },
  );
  const response = propOr(null, ['updatePersonalInfoAndResidency'], data);
  return {updatePersonalInfoAndResidency, response, loading, error};
}
