import {propOr} from 'ramda';

import {noop} from '@renofi/utilities';

import {UPDATE_LENDER_LOGO} from '../../../mutations';
import useMutation from '../../useMutation';

export default function useUpdateLenderLogo({
  onCompleted = noop,
  refetchQueries = [],
  ...options
} = {}) {
  const [updateLenderLogo, {data, loading, error}] = useMutation(
    UPDATE_LENDER_LOGO,
    {
      onCompleted,
      refetchQueries,
      ...options,
    },
  );

  const response = propOr(null, 'updateLenderLogo', data);
  return {updateLenderLogo, response, loading, error};
}
