import {pathOr} from 'ramda';

import {noop} from '@renofi/utilities';

import useMutation from '../useMutation';
import {REMOVE_TASK} from '../../mutations';

export default function useRemoveTask({
  onCompleted = noop,
  refetchQueries = [],
} = {}) {
  const [removeTask, {data, loading, error}] = useMutation(REMOVE_TASK, {
    onCompleted,
    refetchQueries,
  });
  const response = pathOr(null, ['removeTask'], data);
  return {removeTask, response, loading, error};
}
