import React, {Fragment, useMemo} from 'react';

import PropTypes from 'prop-types';
import {groupBy, keys, isEmpty} from 'ramda';

import {Flex, TaskDetailsCard, Text} from '@renofi/components-internal';
import formatMoney from '@renofi/utilities/src/formatMoney';
import {noop} from '@renofi/utilities';
import humanizeSnakeCase from '@renofi/utilities/src/humanizeSnakeCase';
import {filterItemsByBorrowerRole} from '@renofi/utilities';
import {TaskDocument, isBusinessAsset} from '@renofi/modules-internal';
import {basic80} from '@renofi/theme';

import {Label, Value} from '../../../styled';
import {taskStatuses} from '../../../../constants';

import {DOCUMENT_TYPES_TITLE_MAPPER} from './constants';
import {DocumentCard, TotalAssets} from './styled';

const showMoney = (value) => formatMoney(value, {fractional: false});

const AssetsSummary = ({
  areActionsDisabled = true,
  borrowerRole,
  assetDetails = [],
  onClickDocument = noop,
  internal = false,
  onAccept = noop,
  onReject = noop,
  status,
}) => {
  const isCompleted = status === taskStatuses.COMPLETED;
  const filteredAssetDetails = filterItemsByBorrowerRole(assetDetails, {
    borrowerRole,
  });

  const groupedDocuments = useMemo(() => {
    const filteredDocuments = assetDetails
      .filter((s) => s.borrowerRole === borrowerRole)
      .reduce((arr, detail) => arr.concat(detail?.documents || []), []);

    return groupBy(({documentType}) => documentType, filteredDocuments);
  }, [assetDetails, borrowerRole]);

  const totalAssetValue = filteredAssetDetails.reduce(
    (total, s) => total + s.cashOrMarketValue,
    0,
  );

  return (
    <Fragment>
      {!isEmpty(filteredAssetDetails) ? (
        <TaskDetailsCard success={isCompleted}>
          {filteredAssetDetails.map((assetDetail) => {
            const {
              id,
              assetType,
              financialInstitution,
              accountHolderName,
              accountNumber,
              cashOrMarketValue,
              jointAccount,
            } = assetDetail || {};
            const isBusiness = isBusinessAsset(assetType);

            return (
              <Flex key={id} flexWrap="wrap">
                <Label>Asset Type:</Label>
                <Value>
                  {humanizeSnakeCase(assetType)}
                  {!jointAccount ? <span>,</span> : null}
                </Value>
                {jointAccount && (
                  <Fragment>
                    <Label>
                      (Joint account)
                      <span>,</span>
                    </Label>
                  </Fragment>
                )}
                <Fragment>
                  <Label>Financial Institution:</Label>
                  <Value>
                    {financialInstitution}
                    <span>,</span>
                  </Value>
                </Fragment>
                {isBusiness ? (
                  <Fragment>
                    <Label>Business name:</Label>
                    <Value>
                      {accountHolderName}
                      <span>,</span>
                    </Value>
                  </Fragment>
                ) : null}
                <Fragment>
                  <Label>Account number:</Label>
                  <Value>
                    {accountNumber}
                    <span>,</span>
                  </Value>
                </Fragment>
                <Fragment>
                  <Label>Value:</Label>
                  <Value>{showMoney(cashOrMarketValue)}</Value>
                </Fragment>
              </Flex>
            );
          })}

          <TotalAssets>
            Total asset value: {showMoney(totalAssetValue)}
          </TotalAssets>
        </TaskDetailsCard>
      ) : null}
      {keys(groupedDocuments).map((key) => {
        const groupTitle = DOCUMENT_TYPES_TITLE_MAPPER[key] || key;
        const documents = groupedDocuments[key] || [];

        return (
          <Flex key={key} flexDirection="column" width={1}>
            <Text color={basic80} mb={2} mt={3} fontSize={16}>
              {groupTitle}
            </Text>

            <Flex width={1} flexWrap="wrap">
              {documents.map((doc) => {
                return (
                  <DocumentCard mb={2} internal={internal} key={doc.id}>
                    <TaskDocument
                      areActionsDisabled={areActionsDisabled}
                      onClick={() => onClickDocument(doc.id)}
                      internal={internal}
                      onAccept={onAccept}
                      onReject={onReject}
                      showDownloadIcon={internal}
                      immutable={!internal}
                      {...doc}
                    />
                  </DocumentCard>
                );
              })}
            </Flex>
          </Flex>
        );
      })}
    </Fragment>
  );
};

AssetsSummary.propTypes = {
  borrowerRole: PropTypes.string.isRequired,
  areActionsDisabled: PropTypes.bool,
  assetDetails: PropTypes.arrayOf(
    PropTypes.shape({
      borrowerRole: PropTypes.string,
      documents: PropTypes.arrayOf(
        PropTypes.shape({
          borrowerRole: PropTypes.string,
          id: PropTypes.string.isRequired,
        }),
      ),

      id: PropTypes.string.isRequired,
    }),
  ),
  onClickDocument: PropTypes.func,
  status: PropTypes.string,
  internal: PropTypes.bool,
  onAccept: PropTypes.func,
  onReject: PropTypes.func,
};

export default AssetsSummary;
