import {useEffect, useState} from 'react';

import {map, noop} from 'lodash';

import {TASK_OPTIONS_BY_LOAN_TYPE_OR_LENDER} from '../queries';
import useToggledQuery from '../../useToggledQuery';

const toSelected = (t) => ({...t, selected: true});

export default function useTaskOptionsByLoanTypeOrLender({
  fetchPolicy = 'cache-first',
  lazy = false,
  onCompleted = noop,
  ...options
} = {}) {
  const response = useToggledQuery(TASK_OPTIONS_BY_LOAN_TYPE_OR_LENDER, {
    lazy,
    fetchPolicy,
    onCompleted,
    ...options,
  });
  const [tasksToAdd, setTasksToAdd] = useState([]);
  const [tasksToRemove, setTasksToRemove] = useState([]);

  const {data, error, loading, fetch, refetching} = response;

  const onToggleSelected = (setter) => (task) => {
    setter((state) => state.map((t) => (t.id === task.id ? task : t)));
  };

  useEffect(() => {
    const options = data?.taskOptionsByLoanTypeOrLender || {};
    setTasksToAdd(map(options?.tasksToAdd || [], toSelected));
    setTasksToRemove(map(options?.tasksToRemove || [], toSelected));
  }, [JSON.stringify(data)]);

  return {
    fetch,
    tasksToAdd,
    tasksToRemove,
    data,
    loading,
    onToggleSelected,
    error,
    refetching,
    setTasksToAdd,
    setTasksToRemove,
  };
}
