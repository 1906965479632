import {gql} from '@apollo/client';

export default gql`
  mutation submitBorrowersInfo(
    $projectId: ID!
    $primaryApplicant: ApplicantInputObject
    $coborrower: ApplicantInputObject
    $coborrowerSharesSameDependents: Boolean
  ) {
    submitBorrowersInfo(
      projectId: $projectId
      coborrowerSharesSameDependents: $coborrowerSharesSameDependents
      primaryApplicant: $primaryApplicant
      coborrower: $coborrower
    ) {
      project {
        id
      }
    }
  }
`;
