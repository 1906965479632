import {pathOr} from 'ramda';

import {noop} from '@renofi/utilities';

import {REMOVE_CONTRACTOR_INFORMATION} from '../mutations';

import useMutation from './useMutation';

export default function useRemoveContractorInformation({
  onCompleted = noop,
  refetchQueries = [],
} = {}) {
  const [removeContractorInformation, {data, loading, error}] = useMutation(
    REMOVE_CONTRACTOR_INFORMATION,
    {
      onCompleted,
      refetchQueries,
    },
  );
  const response = pathOr(
    null,
    ['removeContractorInformation', 'contractor'],
    data,
  );

  return {removeContractorInformation, response, loading, error};
}
