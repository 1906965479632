import {Fragment} from 'react';

import PropTypes from 'prop-types';
import {propOr} from 'ramda';

import {noop} from '@renofi/utilities';
import {validateUuidProp} from '@renofi/utilities/src/react';

import BorrowerDetails from './BorrowerDetails';

const PersonalInfoDetails = ({hasCoborrower, onValueClick = noop, task}) => {
  const data = propOr({}, 'personalInfoAndResidency', task);

  return (
    <Fragment>
      <BorrowerDetails
        data={data}
        onValueClick={onValueClick}
        status={task?.status}
      />
      {hasCoborrower ? (
        <BorrowerDetails
          status={task?.status}
          data={data}
          coborrower={hasCoborrower}
          onValueClick={onValueClick}
        />
      ) : null}
    </Fragment>
  );
};

PersonalInfoDetails.propTypes = {
  hasCoborrower: PropTypes.bool,
  onValueClick: PropTypes.func,
  task: PropTypes.shape({
    id: validateUuidProp,
    status: PropTypes.string,
    personalInfoAndResidency: PropTypes.shape({
      id: validateUuidProp,
    }),
  }),
};

export default PersonalInfoDetails;
