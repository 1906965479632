import {pathOr} from 'ramda';

import {noop} from '@renofi/utilities';

import useMutation from '../../useMutation';
import {UPDATE_WEB_REVIEW} from '../../../mutations';

export default function useUpdateWebReview({
  onCompleted = noop,
  refetchQueries = [],
} = {}) {
  const [updateWebReview, {data, loading, error}] = useMutation(
    UPDATE_WEB_REVIEW,
    {
      onCompleted,
      refetchQueries,
    },
  );
  const response = pathOr(null, ['updateWebReview', 'webReview'], data);

  return {updateWebReview, response, loading, error};
}
