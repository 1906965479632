import React, {useCallback, useEffect, useState} from 'react';

import PropTypes from 'prop-types';

import {noop} from '@renofi/utilities';
import withForwardRef from '@renofi/utilities/src/withForwardRef';

import FieldFrame from './FieldFrame';
import FieldInput from './FieldInput';

const ActiveField = ({
  autoComplete,
  canClear = false,
  clearValue = null,
  disabled,
  forwardedRef,
  name,
  placeholder,
  type,
  value: currentValue,
  tabIndex,
  onBlur = noop,
  onChange = noop,
  onClear = noop,
  onFocus = noop,
  onKeyDown = noop,
  onKeyUp = noop,
  error,
  inputClassName,
  denyManualClear = false,
  validations = [],
  onValidate = () => {},
  showValidation,
  ...props
}) => {
  const [focus, setFocus] = useState(false);
  const [value, setValue] = useState(currentValue || '');
  const [validationErr, setValidationErr] = useState(null);

  const changeHandler = (val) => {
    if (denyManualClear && !val) {
      onChange(val);
      return;
    }

    setValue(val);
    onChange(val);
  };

  const clearHandler = useCallback(() => {
    if (canClear) {
      setValue(clearValue);
      onClear(clearValue);
      onChange(clearValue);
    }
  }, [canClear, onClear, onChange, clearValue]);

  const blurHandler = (event) => {
    setFocus(false);
    onBlur(event);
  };

  const focusHandler = (event) => {
    setFocus(true);
    onFocus(event);
  };

  const validationHandler = (err, name) => {
    setValidationErr(err);
    onValidate(err, name);
  };

  useEffect(() => {
    setValue(currentValue);
  }, [currentValue]);

  return (
    <FieldFrame
      name={name}
      canClear={canClear}
      disabled={disabled}
      inputValue={value}
      error={showValidation ? validationErr : error}
      focused={focus}
      onClear={clearHandler}
      {...props}>
      <FieldInput
        autoComplete={autoComplete}
        error={error}
        disabled={disabled}
        name={name}
        id={name}
        tabIndex={tabIndex}
        type={type}
        value={value ?? ''}
        ref={forwardedRef}
        placeholder={placeholder}
        onChange={changeHandler}
        onBlur={blurHandler}
        onFocus={focusHandler}
        onKeyDown={onKeyDown}
        onKeyUp={onKeyUp}
        className={inputClassName}
        validations={validations}
        onValidate={validationHandler}
        showValidation={showValidation}
      />
    </FieldFrame>
  );
};

ActiveField.propTypes = {
  autoComplete: PropTypes.string,
  canClear: PropTypes.bool,
  clearValue: PropTypes.any,
  disabled: PropTypes.bool,
  forwardedRef: PropTypes.any,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onClear: PropTypes.func,
  onFocus: PropTypes.func,
  onKeyDown: PropTypes.func,
  onKeyUp: PropTypes.func,
  showIconDivider: PropTypes.bool,
  tabIndex: PropTypes.number,
  inputClassName: PropTypes.string,
  denyManualClear: PropTypes.bool,
  validations: PropTypes.array,
  onValidate: PropTypes.func,
};

export default withForwardRef(ActiveField);
