import {useMemo} from 'react';

import {isNil, propOr} from 'ramda';

import {noop} from '@renofi/utilities';

import {GET_INTERNAL_USERS} from '../../queries';
import useToggledQuery from '../useToggledQuery';

export default function useInternalUsersSearch({
  fetchPolicy = 'cache-first',
  lazy = true,
  onCompleted = noop,
  ...options
} = {}) {
  const response = useToggledQuery({
    lazy,
    query: GET_INTERNAL_USERS,
    options: {
      fetchPolicy,
      onCompleted,
      ...options,
    },
  });
  const {data, error, loading, fetch: searchInternalUsers} = response;

  const internalUsers = useMemo(() => {
    const users = propOr([], 'internalUsersSearch', data);
    return users.filter((user) => !isNil(user.firstName)) || [];
  }, [data]);

  return {searchInternalUsers, internalUsers, data, loading, error};
}
