import {propOr} from 'ramda';

import {noop} from '@renofi/utilities';

import useToggledQuery from '../useToggledQuery';
import {GET_LENDER} from '../../queries';

export default function useLenderById({
  fetchPolicy = 'cache-first',
  lazy = false,
  onCompleted = noop,
  ...options
} = {}) {
  const response = useToggledQuery({
    lazy,
    query: GET_LENDER,
    options: {
      onCompleted,
      fetchPolicy,
      ...options,
    },
  });

  const {data, error, loading, fetch} = response;
  const lender = propOr(null, 'lender', data);
  return {fetch, lender, data, loading, error};
}
