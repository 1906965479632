import {propOr} from 'ramda';

import {noop} from '@renofi/utilities';

import {GET_USER_ACTIONS} from '../../queries';
import useToggledQuery from '../useToggledQuery';

export default function useUserActions({
  fetchPolicy = 'cache-first',
  lazy = false,
  onCompleted = noop,
  ...options
} = {}) {
  const {
    fetch: getUserActions,
    data,
    loading,
    error,
  } = useToggledQuery({
    lazy,
    query: GET_USER_ACTIONS,
    options: {
      onCompleted,
      fetchPolicy,
      ...options,
    },
  });
  const userActions = propOr([], 'userActions', data);
  return {
    getUserActions,
    userActions,
    data,
    loading,
    error,
  };
}
