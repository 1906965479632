/* eslint-disable max-lines */
import {
  actionDark,
  actionExtraLight,
  actionLight,
  actionRegular,
  basic10,
  basic15,
  basic20,
  basic55,
  basic80,
  basic88,
  border,
  renofiBlue,
  successDark,
  successExtraLight,
  successRegular,
  white,
  dangerDark,
  dangerExtraLight,
  dangerLight,
  dangerRegular,
  warningDark,
  warningExtraLight,
  warningRegular,
  borderDark,
  basic75,
} from './colors';
import hexToRgba from './hexToRgba';

export const alert = {
  common: {
    minHeight: 50,
    height: 'auto !important',
    borderRadius: '.25rem',
  },
  danger: {
    backgroundColor: '#FFEEEE',
    color: '#993131',
  },
  dangerVibrant: {
    backgroundColor: dangerRegular,
    color: white,
  },
  info: {
    backgroundColor: actionExtraLight,
    color: '#006099',
  },
  infoVibrant: {
    backgroundColor: '#00A0FF',
    color: white,
  },
  success: {
    backgroundColor: successExtraLight,
    color: '#0B7517',
  },
  successVibrant: {
    backgroundColor: '#13C427',
    color: white,
  },
  warning: {
    color: warningDark,
    backgroundColor: warningExtraLight,
  },
};

export const badge = {
  info: {
    color: basic80,
    backgroundColor: actionExtraLight,
  },
  default: {
    color: basic55,
    backgroundColor: white,
    border: `1px solid ${basic20}`,
  },
  complete: {
    color: successDark,
    backgroundColor: successExtraLight,
  },
  neutral: {
    color: basic55,
    backgroundColor: hexToRgba(basic20, 0.2),
  },
  success: {
    color: successDark,
    backgroundColor: successExtraLight,
  },
  successInverse: {
    color: white,
    backgroundColor: successRegular,
  },
  warning: {
    color: warningDark,
    backgroundColor: warningExtraLight,
  },
  warningInverse: {
    color: white,
    backgroundColor: warningRegular,
  },
  danger: {
    color: dangerRegular,
    backgroundColor: dangerExtraLight,
  },
  dangerVibrant: {
    color: dangerRegular,
    backgroundColor: dangerExtraLight,
  },
  dangerInverse: {
    color: white,
    backgroundColor: dangerRegular,
  },
  defaultVibrant: {
    color: renofiBlue,
    backgroundColor: white,
  },
  action: {
    color: actionRegular,
    border: `1px solid ${actionRegular}`,
    backgroundColor: white,
  },
};

export const button = ({active, strong}) => ({
  common: {
    fontWeight: strong ? 600 : 400,
  },
  renofi: {
    color: white,
    backgroundColor: renofiBlue,
    border: `1px solid ${renofiBlue}`,
    ':hover': {
      backgroundColor: actionDark,
      border: `1px solid ${actionDark}`,
    },
  },
  primary: {
    color: white,
    backgroundColor: actionRegular,
    border: `1px solid ${actionRegular}`,
    ':hover': {
      backgroundColor: actionDark,
      border: `1px solid ${actionDark}`,
    },
  },
  secondary: {
    color: active ? actionRegular : basic55,
    backgroundColor: active ? actionExtraLight : white,
    border: `1px solid ${active ? actionRegular : basic20}`,
    ':hover': {
      color: actionRegular,
      backgroundColor: actionExtraLight,
      border: `1px solid ${actionRegular}`,
    },
  },
  outline: {
    color: basic80,
    backgroundColor: white,
    border: '2px solid #CED4DA',
    ':hover': {
      borderColor: '#BBBBBB',
    },
  },
  danger: {
    color: white,
    backgroundColor: dangerRegular,
    border: `1px solid ${dangerRegular}`,
    ':hover': {
      backgroundColor: dangerDark,
      border: `1px solid ${dangerDark}`,
    },
  },
  dangerVibrant: {
    color: dangerRegular,
    backgroundColor: white,
    border: `1px solid ${dangerRegular}`,
    ':hover': {
      color: white,
      backgroundColor: dangerRegular,
    },
  },
  success: {
    color: white,
    backgroundColor: successRegular,
    border: `1px solid ${successRegular}`,
    ':hover': {
      backgroundColor: successDark,
      border: `1px solid ${successDark}`,
    },
  },
  successMuted: {
    color: successDark,
    backgroundColor: successExtraLight,
    border: `1px solid ${successExtraLight}`,
    ':hover': {
      backgroundColor: successExtraLight,
      border: `1px solid ${successExtraLight}`,
    },
  },
  successOutline: {
    color: successDark,
    backgroundColor: successExtraLight,
    border: `1px solid ${successDark}`,
    ':hover': {
      backgroundColor: successExtraLight,
      border: `1px solid ${successDark}`,
    },
  },
  disabled: {
    color: 'rgba(0,0,0,0.2)',
    backgroundColor: basic10,
    border: `1px solid ${basic10}`,
  },
  link: {
    color: actionDark,
    background: 'none',
    border: 'none',
    textTransform: 'capitalize',
    fontSize: 14,
  },
  transparent: {
    color: basic10,
    background: 'none',
    border: `1px solid ${basic20}`,
    ':hover': {
      border: `1px solid ${basic55}`,
    },
  },
  warning: {
    background: warningRegular,
    color: white,
    border: `1px solid ${warningRegular}`,
    ':hover': {
      backgroundColor: warningDark,
      border: `1px solid ${warningDark}`,
    },
  },
});

export const dropZone = ({active, hover}) => {
  const isActive = active || hover;
  return {
    default: {
      backgroundColor: isActive ? actionExtraLight : white,
      borderColor: isActive ? actionExtraLight : '#DBDBDB',
      borderStyle: isActive ? 'solid' : 'dashed',
      borderWidth: 2,
      ':hover': {
        border: `1.5px solid ${actionRegular}`,
        backgroundColor: actionExtraLight,
      },
    },
  };
};

export const navbar = {
  primary: {
    color: white,
    backgroundColor: renofiBlue,
  },
  secondary: {
    color: renofiBlue,
    backgroundColor: white,
    boxShadow: '0 2px 8px 0 rgba(22,53,81,0.2)',
  },
  crystal: {
    color: renofiBlue,
    backgroundColor: '#F5F6F7',
    boxShadow: '0 1px 0 0 rgba(0,0,0,0.1)',
  },
  alternative: {
    color: white,
    backgroundColor: actionRegular,
  },
};

export const label = ({small}) => {
  return {
    common: {
      fontSize: small ? 13 : 14,
      lineHeight: '17px',
    },
    default: {
      color: small ? basic75 : basic55,
    },
    error: {
      color: small ? dangerDark : dangerRegular,
    },
  };
};

export const textarea = ({focus}) => {
  return {
    common: {
      border: `1px solid ${border}`,
      borderColor: focus ? actionRegular : border,
      borderStyle: 'solid',
      borderRadius: 4,
      '> textarea': {
        color: basic80,
        '&::placeholder': {
          color: basic55,
        },
      },
      ':hover': {
        borderColor: focus ? actionRegular : actionLight,
        boxShadow: focus ? 'none' : `0 0 0 2px ${actionLight}`,
      },
    },
    default: {
      backgroundColor: white,
    },
    disabled: {
      backgroundColor: 'rgba(0,0,0,0.05)',
      '> textarea': {
        color: basic55,
      },
    },
    error: {
      borderColor: dangerRegular,
    },
  };
};

export const checkbox = ({disabled}) => ({
  default: {
    color: disabled ? basic55 : basic80,
  },
});

export const textField = ({
  border: showBorder = true,
  disabled,
  inactive,
  focused,
  invalid,
  large,
  left,
  right,
  small,
}) => {
  const invalidColor = small ? dangerDark : dangerLight;

  return {
    common: {
      border: showBorder ? `1px solid ${small ? borderDark : border}` : 'none',
      borderRadius: small ? 2 : 4,
      color: invalid ? dangerDark : basic80,
      borderColor: invalid
        ? invalidColor
        : focused && !inactive
          ? actionRegular
          : small
            ? borderDark
            : border,
      '> div': {
        padding: large ? '16px' : '12px',
        fontSize: small ? 15 : 16,
        color: invalid ? invalidColor : basic80,
        '> input': {
          color: basic80,
          fontSize: small ? 15 : 16,
        },
        '> input::placeholder': {
          color: basic55,
          fontSize: small ? 15 : 16,
        },
      },
      ':hover': {
        ...(focused || inactive || disabled
          ? {}
          : {
              borderColor: invalid ? invalidColor : '#ADADAD',
            }),
        ...(invalid ? {background: small ? dangerExtraLight : 'inherit'} : {}),
      },
      '> span': {
        ...(left
          ? {
              borderRight: `1px solid ${border}`,
              borderRadius: '4px 0 0 4px',
              marginLeft: 1,
            }
          : {}),
        ...(right
          ? {
              borderLeft: `1px solid ${border}`,
              borderRadius: '0 4px 4px 0',
              marginRight: 1,
            }
          : {}),
        borderColor: invalid
          ? dangerLight
          : focused && !inactive
            ? actionRegular
            : border,
      },
      ':hover > span':
        focused || inactive || disabled
          ? {}
          : {
              ...(left
                ? {
                    borderRight: `3px solid ${actionLight}`,
                    marginLeft: 0,
                    marginRight: 1,
                  }
                : {}),
              ...(right
                ? {
                    borderLeft: `3px solid ${actionLight}`,
                    marginLeft: 1,
                    marginRight: 0,
                  }
                : {}),
            },
    },
    default: {
      backgroundColor: white,
    },
    disabled: {
      color: basic55,
      '> div': {
        padding: large ? '0 16px' : '0 12px',
        fontSize: small ? 15 : 16,
        color: basic55,
        '> input': {
          color: small ? basic55 : basic80,
          fontSize: small ? 15 : 16,
        },
      },
      backgroundColor: 'rgba(0,0,0,0.05)',
    },
  };
};

export const buttonGroup = ({active}) => ({
  common: {
    fontSize: 16,
    lineHeight: '24px',
    cursor: 'pointer',
    color: active ? white : basic55,
    border: `1px solid ${basic20}`,
    ':first-of-type': {
      borderRadius: '4px 0 0 4px',
    },
    ':last-of-type': {
      borderLeft: 'none',
      borderRadius: '0 4px 4px 0',
    },
  },
  disabled: {
    cursor: 'default',
    color: active ? white : 'rgba(0,0,0,0.2)',
    backgroundColor: active ? '#888888' : basic10,
    border: `1px solid ${basic15}`,
  },
  default: {
    backgroundColor: active ? actionRegular : white,
  },
});

export const selectField = ({invalid, small}) => ({
  common: {
    fontSize: 16,
    lineHeight: '24px',
    border: `1px solid ${border}`,
    borderRadius: small ? 2 : 4,
    ':focus': {
      border: 'solid 3px black',
    },
    '> div': {
      fontSize: 16,
      lineHeight: '24px',
    },
  },
  default: {
    padding: '0 16px',
    cursor: 'pointer',
    backgroundColor: white,
    color: invalid ? dangerRegular : basic80,
    borderColor: invalid ? 'rgba(220,53,69,0.2)' : border,
  },
  disabled: {
    cursor: 'default',
    color: basic55,
    backgroundColor: 'rgba(0,0,0,0.05)',
  },
});

export const options = {
  common: {
    boxShadow: '0 2px 8px 0 rgba(0,0,0,0.2)',
  },
  default: {
    backgroundColor: white,
  },
};

export const option = ({focus}) => ({
  default: {
    color: basic80,
    backgroundColor: focus ? actionExtraLight : white,
    ':hover': {
      backgroundColor: actionExtraLight,
    },
  },
});

export const dateField = {
  common: {
    border: '1px solid #CED4DA',
    backgroundColor: white,
    boxShadow: '0 2px 7px 0 rgba(0,0,0,0.2)',
    '& .DayPicker-Day': {
      color: basic80,
    },
  },
};

export const radioButton = ({checked, disabled, small, height, help}) => {
  const hasHelpLabel = Boolean(help);

  return {
    common: {
      minWidth: 100,
      borderRadius: 4,
      fontSize: 15,
      lineHeight: '18px',
      color: '#6C757D',
      border: checked
        ? `1px solid ${disabled ? '#CED4DA' : '#3B88FD'}`
        : '1px solid #CED4DA',
      backgroundColor: disabled ? '#F8F8F8' : checked ? '#F1F9FB' : 'white',
      padding: 16,
      height: hasHelpLabel ? 'auto' : small ? 36 : height || 50,
      alignItems: hasHelpLabel ? 'baseline' : 'center',
      cursor: disabled ? 'not-allowed' : 'pointer',
    },
  };
};

export const tab = ({active}) => ({
  common: {
    height: active ? 50 : 40,
    background: white,
    border: active ? `1px solid ${basic15}` : 'none',
    ...(active ? {borderTop: `4px solid ${actionRegular}`} : {}),
    borderBottom: active ? 0 : `1px solid ${basic15}`,
    color: basic88,
  },
});

export const toggle = {
  common: {
    background: successRegular,
    border: 'none',
    width: 37,
    height: 20,
    cursor: 'pointer',
    '> div': {
      background: white,
      border: 'none',
      width: 13,
      height: 13,
      marginLeft: '4px',
    },
    ':focus': {
      boxShadow: 'none',
    },
    '&[aria-checked=false]': {
      background: dangerRegular,
    },
    '&[disabled]': {
      background: basic20,
      cursor: 'not-allowed',
    },
  },
};

export default {
  alert,
  badge,
  button,
  buttonGroup,
  checkbox,
  dateField,
  dropZone,
  label,
  navbar,
  option,
  options,
  radioButton,
  selectField,
  tab,
  textarea,
  textField,
  toggle,
};
