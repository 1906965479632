import {datadogRum} from '@datadog/browser-rum';

import logger from '@renofi/utilities/src/logger';
import humanizeSnakeCase from '@renofi/utilities/src/humanizeSnakeCase';

import {RENOFI_GRAPHQL_ERROR} from '../constants';

import {AUTH_ERRORS} from './constants';
import parsePermissionsErrors from './parsePermissionsErrors';

const UNKNOWN_ERROR = 'Unknown Error';

const humanize = (msg = '') => humanizeSnakeCase(msg.toLowerCase());

const getMessage = (e) => e?.message || UNKNOWN_ERROR;

const getIsAuthError = (errors = []) =>
  errors.some((err) => AUTH_ERRORS.includes(getMessage(err)));

const getOperationTypes = (operation) => {
  const {definitions = []} = operation?.query || {};
  return definitions.map(({operation}) => operation);
};

const parseGraphQLErrors = ({graphQLErrors = [], operation = {}} = {}) => {
  const {operationName} = operation;
  const variables = operation?.variables || {};
  const operationType = getOperationTypes(operation);
  const gqlError = new Error(`${RENOFI_GRAPHQL_ERROR}:${operationName}`);
  const isAuthError = getIsAuthError(graphQLErrors);

  parsePermissionsErrors({errors: graphQLErrors, operationName});

  if (isAuthError) {
    return;
  }

  const extra = graphQLErrors.reduce(
    (obj, error) => {
      const extensions = error?.extensions || {};
      const message = humanize(getMessage(error));
      return {
        ...obj,
        data: (obj?.data || []).concat({message, extensions}),
      };
    },
    {operationName, operationType, variables},
  );

  // Only surface for mutations; ignore queries...
  if (operationType.includes('mutation')) {
    global.dispatchEvent(
      new CustomEvent(RENOFI_GRAPHQL_ERROR, {
        detail: {
          error: gqlError,
          ...extra,
        },
      }),
    );
  }

  logger.error(gqlError);
  datadogRum.addError(gqlError, extra);
};

export default parseGraphQLErrors;
