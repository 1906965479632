import {pathOr} from 'ramda';

import {SUBMIT_ORDER_COMMENTS} from '@renofi/api';
import {compose, withHandlers} from '@renofi/recompose';

import withApollo from './withApollo';
import withMutation from './withMutation';

export default () =>
  compose(
    withApollo,
    withMutation(SUBMIT_ORDER_COMMENTS, {
      name: 'submitOrderComments',
      options: {
        refetchQueries: ['appraisalOrdersByProjectId'],
      },
    }),
    withHandlers({
      submitOrderComments:
        ({submitOrderComments}) =>
        async (variables) => {
          const response = await submitOrderComments({
            variables,
          });
          const success = pathOr(
            false,
            ['data', 'submitOrderComments', 'success'],
            response,
          );
          return success;
        },
    }),
  );
