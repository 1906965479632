import {ApolloLink, HttpLink} from '@apollo/client';
import {setContext} from '@apollo/client/link/context';
import {onError} from '@apollo/link-error';
import {any, pathOr, propEq} from 'ramda';

import {getJwt} from '@renofi/utilities/src/auth';
import logger from '@renofi/utilities/src/logger';
import {getLocation} from '@renofi/utilities/src/window';
import {getCookie} from '@renofi/utilities/src/cookies';
import jwtDecode from '@renofi/utilities/src/jwtDecode';
import logoutWithRedirect from '@renofi/utilities/src/logoutWithRedirect';
import badQueryErrorLink from '@renofi/utils/src/apollo/badQueryErrorLink';
import {dataDogLink} from '@renofi/api';

import parseGraphQLErrors from '../utils/parseGraphQLErrors';

import mockLink from './mockLink';

const UNAUTHENTICATED_ERROR = 'UNAUTHENTICATED_ERROR';
const hasAuthMessage = any(propEq('message', UNAUTHENTICATED_ERROR));

export default ({context, uri}) => {
  const {config, serviceAccountId, storage} = context;
  const errorReportingEnabled =
    process.env.REACT_APP_ENABLE_GRAPHQL_ERROR_REPORTING === 'true';
  logger.log('ENABLE_GRAPHQL_ERROR_REPORTING', errorReportingEnabled);

  const authLink = setContext((_, {headers, ...context}) => {
    const jwt = getJwt({storage});
    const lastActivity = storage.getItem('auth:action') || Date.now();

    return {
      ...context,
      headers: {
        ...headers,
        ...(jwt ? {Authorization: jwt} : {}),
        'X-Last-Activity': lastActivity,
        'X-Renofi-Origin': window?.location?.href,
        ...(serviceAccountId ? {'X-Service-Account-ID': serviceAccountId} : {}),
      },
    };
  });

  const errorLink = onError(({operation, ...params}) => {
    const context = operation.getContext();
    const statusCode = pathOr(null, ['response', 'status'], context);
    const isAuthStatusError = statusCode === 401 || statusCode === 403;
    const {graphQLErrors = []} = params || {};
    const isAuthErrorResponse = hasAuthMessage(graphQLErrors);
    const isAuthError = isAuthErrorResponse || isAuthStatusError;
    const location = getLocation();
    const isAtLogin = location.hostname.includes('login');

    if (isAuthError && !isAtLogin) {
      storage.clear();
      logoutWithRedirect();
    }
    if (errorReportingEnabled && !isAuthError && graphQLErrors.length) {
      parseGraphQLErrors({graphQLErrors, operation});
    }
  });

  const httpLink = new HttpLink({
    uri,
    fetch: (resource, init = {}) => {
      const cfToken = getCookie('CF_Authorization');
      if (cfToken) {
        try {
          const {exp} = jwtDecode(cfToken);
          const date = new Date(0);
          date.setUTCSeconds(exp);
          if (date.getTime() < Date.now()) {
            getLocation().reload();
          }
        } catch (error) {
          // ignore
        }
      }
      return fetch(resource, {...init, redirect: 'manual'}).then((response) => {
        if (response.status === 0 && response.type === 'opaqueredirect') {
          getLocation().reload();
        }

        if (response.status >= 490 && response.status <= 499) {
          return new Response(response.body, {...response, status: 200});
        }

        return response;
      });
    },
  });

  const responseLink = new ApolloLink((operation, forward) => {
    return forward(operation).map((result) => {
      const context = operation.getContext();
      const lastActivity = context.response.headers.get('X-Last-Activity');

      if (lastActivity) {
        storage.setItem('auth:action', lastActivity);
      }
      return result;
    });
  });

  return ApolloLink.from([
    ...mockLink(config),
    badQueryErrorLink,
    ...(errorReportingEnabled ? [errorLink] : []),
    responseLink,
    dataDogLink,
    authLink,
    httpLink,
  ]);
};
