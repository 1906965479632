import {noop} from '@renofi/utilities';

import useMutation from '../../useMutation';
import {CREATE_TASKS} from '../../../mutations';
import {useUpdateCachedTask} from '../../../utils';

export default function useCreateTasks({
  onCompleted = noop,
  refetchQueries = [],
  updateCache = true,
  ...options
} = {}) {
  const updateCachedTask = useUpdateCachedTask();
  const [mutation, {loading, error}] = useMutation(CREATE_TASKS, {
    onCompleted,
    refetchQueries,
    ...options,
  });

  const createTasks = async (args) => {
    const rsp = await mutation(args);
    const {tasks = []} = rsp?.data?.createTasks || {};
    const projectId = args?.variables?.projectId;

    if (Boolean(rsp?.errors) || !updateCache || !tasks.length || !projectId) {
      return rsp;
    }

    await Promise.all(tasks.map((task) => updateCachedTask({projectId, task})));

    return rsp;
  };

  return {createTasks, loading, error};
}
