import React, {useMemo} from 'react';

import PropTypes from 'prop-types';
import {format} from 'date-fns';

import {SimpleDateField} from '@renofi/components-internal';
import {noop} from '@renofi/utilities';

const DATE_PLACEHOLDER = 'MM/DD/YYYY';
const DATE_MASK = DATE_PLACEHOLDER.replace(/\//gi, '').split('');

const getDisplayValue = (date) => {
  if (!date) {
    return null;
  }
  const [year, month, day] = date.split('-');
  return [month, day, year].join('/');
};

const setUpstreamValue = (dob) => {
  if (!dob) {
    return null;
  }

  const [month, day, year] = dob.split('/');
  return [year, month, day].join('-');
};

const DateField = ({
  onChange = noop,
  onClickIcon = noop,
  outputFormat = 'yyyy-MM-dd',
  onError = noop,
  value = '',
  width,
  ...props
}) => {
  const displayValue = useMemo(() => {
    const isDate = value instanceof Date;
    if (isDate) {
      return getDisplayValue(format(value, outputFormat));
    }

    return getDisplayValue(value);
  }, [value]);

  const onChangeDate = (date, isValid) => {
    if (!isValid) {
      onError(isValid);
      return onChange('');
    }

    onChange(setUpstreamValue(date));
  };

  return (
    <SimpleDateField
      format="MM/dd/yyyy"
      placeholder={DATE_PLACEHOLDER}
      mask={DATE_MASK}
      onChange={(...args) => onChangeDate(...args)}
      onClickIcon={onClickIcon}
      value={displayValue}
      css={{width}}
      {...props}
    />
  );
};

DateField.propTypes = {
  onChange: PropTypes.func,
  onClickIcon: PropTypes.func,
  onError: PropTypes.func,
  outputFormat: PropTypes.string,
  value: PropTypes.string,
  width: PropTypes.number,
};

export default DateField;
