import {useMutation} from '@apollo/client';
import {noop} from 'lodash';

import {CANCEL_APPRAISAL_ORDER} from '../../../mutations';

export default function useCancelAppraisalOrder({
  onCompleted = noop,
  refetchQueries = [],
} = {}) {
  const [cancelAppraisalOrder, {data, loading, error}] = useMutation(
    CANCEL_APPRAISAL_ORDER,
    {
      onCompleted,
      refetchQueries,
    },
  );

  const response = data?.cancelAppraisalOrder?.success;

  return {cancelAppraisalOrder, response, loading, error};
}
