import {propOr} from 'ramda';

import {noop} from '@renofi/utilities';

import useMutation from '../../useMutation';
import {TRANSMIT_RASS_PROJECT_TO_LENDER} from '../../../mutations';

export default function useTransmitRaasProjectToLender(
  onCompleted = noop,
  refetchQueries = [],
) {
  const [transmitRaasProjectToLender, {data, loading, error}] = useMutation(
    TRANSMIT_RASS_PROJECT_TO_LENDER,
    {
      onCompleted,
      refetchQueries,
    },
  );

  const response = propOr(null, 'transmitRaasProjectToLender', data);
  return {transmitRaasProjectToLender, response, loading, error};
}
