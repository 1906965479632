import React, {Fragment, useEffect, useState} from 'react';

import {useParams} from 'react-router-dom';
import {isNil, find, merge, pick} from 'lodash';
import PropTypes from 'prop-types';
import {getContractorName, useCanEditGcddReview} from 'lib/gcdd';
import {Container, StatusBadge, getGcddDocumentStatus} from 'modules/viewer-v2';
import Row from 'modules/viewer-v2/components/Row';
import Col from 'modules/viewer-v2/components/Col';

import {
  ButtonGroup,
  DateField,
  MoneyField,
  Text,
  TextField,
  Textarea,
} from '@renofi/components-internal';
import {basic55} from '@renofi/theme';
import {
  useUpdateInsurance,
  useSubmitReviewNotes,
  GET_GCDD_REVIEWS_BY_PROJECT_ID,
} from '@renofi/graphql';
import useDebounce from '@renofi/utilities/src/useDebounce';
import {useQueryString} from '@renofi/utilities/src/hooks';

import {Checkbox} from './styled';
import {INSURANCE_PROPS, DOCUMENT_TYPES} from './constants';
import {validateForm} from './utils';

function Insurance({documents, gcddReview}) {
  const {projectId, ...params} = useParams();
  const refetchQueries = [
    {
      query: GET_GCDD_REVIEWS_BY_PROJECT_ID,
      variables: {projectId},
    },
  ];

  const {submitReviewNotes} = useSubmitReviewNotes({refetchQueries});
  const {updateInsurance} = useUpdateInsurance({refetchQueries});
  const {canEdit} = useCanEditGcddReview(gcddReview);
  const search = useQueryString();

  const documentId = search?.documentId || params?.documentId;
  const {licenseNotes, referencesNotes, webReviewsNotes, questionnaire} =
    gcddReview;
  const {id: gcddReviewId} = gcddReview || {};
  const contractorName = getContractorName(gcddReview);

  const [reviewNotes, setReviewNotes] = useState(
    gcddReview?.insuranceNotes || '',
  );
  const [insurance, setInsurance] = useState(null);
  const [showComments, setShowComments] = useState(Boolean(reviewNotes));

  const {documentType} = insurance || {};
  const isWorkersCompIncluded =
    insurance?.workersCompDetailsAvailableInGeneralLiability;
  const {status, variant} = getGcddDocumentStatus(insurance, questionnaire);

  const isGeneralLiabilityInsurance =
    documentType === DOCUMENT_TYPES.GENERAL_LIABILITY_INSURANCE;
  const isWorkersCompInsurance =
    documentType === DOCUMENT_TYPES.WORKERS_COMP_INSURANCE;

  const disabled = isNil(insurance) || !canEdit;

  const debouncedUpdateInsurance = useDebounce(updateInsurance, 500);
  const debouncedSubmitReviewNotes = useDebounce(submitReviewNotes, 1000);

  useEffect(() => {
    const currentInsurance = find(documents, ['id', documentId]);

    setInsurance((state) => {
      if (state?.documentType !== currentInsurance?.documentType) {
        return currentInsurance;
      }
      return merge(state, currentInsurance);
    });
  }, [JSON.stringify(documents), documentId]);

  const onChangeNotes = async (value) => {
    setReviewNotes(value);
    await debouncedSubmitReviewNotes({
      variables: {
        gcddReviewId,
        licenseNotes,
        insuranceNotes: value,
        referencesNotes,
        webReviewsNotes,
      },
    });
  };

  const onChangeValue = async (key, value) => {
    const updated = {...insurance, [key]: value};
    const picked = pick(updated, INSURANCE_PROPS);
    const {expiryDate, namesMatch} = updated || {};
    const isFormValid = validateForm(updated);

    setInsurance((state) => ({...state, [key]: value}));
    if (!insurance?.id || !isFormValid) {
      return;
    }

    await debouncedUpdateInsurance({
      variables: {
        expiryDate,
        namesMatch,
        id: insurance?.id,
        insurance: picked,
      },
    });
  };

  return (
    <Container
      header={
        <Row alignItems="center">
          <Text mr={2}>Insurance (with stated limits)</Text>
          <StatusBadge variant={variant}>{status}</StatusBadge>
        </Row>
      }
      collapsible={false}
      comment
      toggleCommentSection={() => setShowComments(!showComments)}>
      {showComments && (
        <Row>
          <Col width={1}>
            <Textarea
              label="Add a section comment"
              help="Comments are visible to the lender"
              rows={4}
              value={reviewNotes}
              onChange={onChangeNotes}
            />
          </Col>
        </Row>
      )}
      <Row>
        <Col>
          <ButtonGroup
            disabled={disabled}
            label="Name on cert matches actual name"
            onChange={(v) => onChangeValue('namesMatch', v)}
            value={insurance?.namesMatch}
          />
        </Col>
        <Col>
          <TextField
            active
            disabled
            rightIcon="lock"
            label="Actual contractor name"
            value={contractorName}
          />
        </Col>
      </Row>

      {isGeneralLiabilityInsurance && (
        <Fragment>
          <Row title="General liability">
            <Col>
              <MoneyField
                disabled={disabled}
                label="Each occurrence"
                onChange={(v) => onChangeValue('limitEachOccurrence', v)}
                value={insurance?.limitEachOccurrence || ''}
              />
            </Col>
            <Col>
              <MoneyField
                disabled={disabled}
                label="Aggregate"
                onChange={(v) => onChangeValue('limitAggregate', v)}
                value={insurance?.limitAggregate || ''}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <DateField
                disabled={disabled}
                label="General liability insurance expiry date"
                onChange={(v) => onChangeValue('expiryDate', v)}
                value={insurance?.expiryDate}
              />
            </Col>
          </Row>
          <Row>
            <Checkbox
              checked={isWorkersCompIncluded}
              onChange={(v) =>
                onChangeValue(
                  'workersCompDetailsAvailableInGeneralLiability',
                  v,
                )
              }>
              <Text ml={2} color={basic55} fontSize={16}>
                Workers comp details are included in the General liability
                insurance document.
              </Text>
            </Checkbox>
          </Row>
        </Fragment>
      )}

      {(isWorkersCompInsurance || isWorkersCompIncluded) && (
        <Row title="Workers comp">
          <Col>
            <MoneyField
              disabled={disabled}
              label="Each accident"
              onChange={(v) => onChangeValue('limitWorkersCompensation', v)}
              value={insurance?.limitWorkersCompensation || ''}
            />
          </Col>

          {isWorkersCompInsurance && !isWorkersCompIncluded && (
            <Col>
              <DateField
                disabled={disabled}
                label="Workers comp insurance expiry date"
                onChange={(v) => onChangeValue('expiryDate', v)}
                value={insurance?.expiryDate}
              />
            </Col>
          )}
        </Row>
      )}
    </Container>
  );
}

Insurance.propTypes = {
  gcddReview: PropTypes.shape({
    id: PropTypes.string.isRequired,
    project: PropTypes.shape({
      timeline: PropTypes.shape({
        expectedRenovationCompletionOn: PropTypes.string,
        expectedRenovationStart: PropTypes.string,
      }).isRequired,
    }).isRequired,
    questionnaire: PropTypes.shape({
      contactFirstName: PropTypes.string,
      contactLastName: PropTypes.string,
      returnedAt: PropTypes.string,
    }),
    licenseNotes: PropTypes.string,
    referencesNotes: PropTypes.string,
    webReviewsNotes: PropTypes.string,
  }),

  documents: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  ),
};

export default Insurance;
