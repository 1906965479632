import React, {useState} from 'react';

import PropTypes from 'prop-types';
import {NumericFormat} from 'react-number-format';

import {noop} from '@renofi/utilities';

import FieldFrame from './FieldFrame';
import FieldInput from './FieldInput/Component';

const MoneyField = ({
  canClear = false,
  clearValue = null,
  disabled,
  fractional = true,
  error,
  value,
  name,
  onChange = noop,
  onClear = noop,
  onBlur = noop,
  onFocus = noop,
  emptyAsNull,
  placeholder,
  tabIndex = 0,
  smallIcon,
  autoFocus,
  small,
  allowNegative = false,
  validations = [],
  onValidate = () => {},
  showValidation,
  ...props
}) => {
  const [focus, setFocus] = useState(false);
  const decimalScale = fractional ? 2 : 0;
  const [validationErr, setValidationErr] = useState(null);
  const onInputChange = (e) => {
    const {value = ''} = e?.target || {};
    const convertedValue = value.replace(/[$,]+/g, '');

    if (emptyAsNull && !convertedValue) {
      return onChange(null);
    }

    const floatValue = Number(convertedValue);
    onChange(fractional ? floatValue : parseInt(floatValue, 10));
  };

  const clearHandler = () => {
    onClear(clearValue);
  };

  const blurHandler = (event) => {
    setFocus(false);
    onBlur(event);
  };

  const focusHandler = (event) => {
    setFocus(true);
    onFocus(event);
  };

  const validationHandler = (err, name) => {
    setValidationErr(err);
    onValidate(err, name);
  };

  return (
    <FieldFrame
      small={small}
      smallIcon={smallIcon}
      canClear={canClear}
      disabled={disabled}
      inputValue={value}
      leftIcon={small ? undefined : 'dollar'}
      error={showValidation ? validationErr : error}
      focused={focus}
      name={name}
      onClear={clearHandler}
      {...props}>
      <NumericFormat
        allowNegative={allowNegative}
        customInput={FieldInput}
        decimalScale={decimalScale}
        disabled={disabled}
        autoFocus={autoFocus}
        type="text"
        value={value}
        name={name}
        id={name}
        placeholder={placeholder}
        prefix={small ? '$' : ''}
        onBlur={blurHandler}
        onFocus={focusHandler}
        onChange={onInputChange}
        tabIndex={tabIndex}
        thousandSeparator=","
        thousandsGroupStyle="thousand"
        validations={validations}
        onValidate={validationHandler}
        showValidation={showValidation}
        passFullEvent
      />
    </FieldFrame>
  );
};

MoneyField.propTypes = {
  canClear: PropTypes.bool,
  clearValue: PropTypes.any,
  disabled: PropTypes.bool,
  fractional: PropTypes.bool,
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onClear: PropTypes.func,
  onFocus: PropTypes.func,
  placeholder: PropTypes.string,
  emptyAsNull: PropTypes.bool,
  tabIndex: PropTypes.number,
  allowNegative: PropTypes.bool,
  small: PropTypes.bool,
  autoFocus: PropTypes.bool,
  validations: PropTypes.array,
  onValidate: PropTypes.func,
  showValidation: PropTypes.bool,
};

export default MoneyField;
