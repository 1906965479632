import React from 'react';

import {omit, pick} from 'ramda';

export default (keys = []) =>
  (Wrapper) =>
  (Component) =>
  ({
    children, // eslint-disable-line react/prop-types
    ...props
  }) => (
    <Wrapper {...omit(keys, props)}>
      <Component {...pick(keys, props)}>{children}</Component>
    </Wrapper>
  );
