import React from 'react';

import PropTypes from 'prop-types';

import {useKeyHandler} from '@renofi/utilities/src/hooks';
import {noop} from '@renofi/utilities';

import {Container, Cover, Wrapper} from './styled';

const BasicModal = ({children, onClose, isNestedModal, ...props}) => {
  useKeyHandler({
    Escape: onClose,
  });

  return (
    <Wrapper isNestedModal={isNestedModal}>
      <Cover isNestedModal={isNestedModal} />
      <Container flexDirection="column" alignItems="center" {...props}>
        {children}
      </Container>
    </Wrapper>
  );
};

BasicModal.propTypes = {
  children: PropTypes.node.isRequired,
  isNestedModal: PropTypes.bool,
  onClose: PropTypes.func,
};

BasicModal.defaultProps = {
  onClose: noop,
  isNestedModal: false,
};

export default BasicModal;
