import useToggledQuery from '../../useToggledQuery';
import {GET_LICENSES} from '../../queries';

export default function useLicenses({
  fetchPolicy = 'cache-first',
  lazy = false,
} = {}) {
  const response = useToggledQuery(GET_LICENSES, {
    lazy,
    fetchPolicy,
  });

  const {data, error, loading, fetch} = response;
  const licenses = data?.licenses || [];

  return {error, fetch, licenses, loading};
}
