/* eslint-disable max-lines */
import {gql} from '@apollo/client';

import {partials} from '@renofi/graphql';

import productDetailsConfirmation from './partials/productDetailsConfirmation';
import advisorFinalCallSchedule from './partials/advisorFinalCallSchedule';
import certificateAcknowledgement from './partials/certificateAcknowledgement';

const {
  additionalCreditLiability,
  additionalDocumentRequest,
  agreement,
  assetDetails,
  borrowersAndPropertyInformation,
  contractor,
  employmentHistory,
  incomeCommentary,
  incomeSource,
  loanTypeValue,
  applicant,
  opportunity,
  projectUser,
  underwritingCondition,
  lender,
  softCreditCheck,
  personalInfoAndResidency,
} = partials;

export default gql`
  query projectDetails($id: ID!) {
    projectById(id: $id) {
      id
      primaryApplicant {
        ${applicant}
        user {
          accountCategories
          currentAgreements {
            ${agreement}
          }
          fullName
          id
          registrationCompletedAt
        }
      }
      coborrower {
        ${applicant}
        user {
          accountCategories
          currentAgreements {
            ${agreement}
          }
          fullName
          id
          registrationCompletedAt
        }
      }
      additionalCreditLiabilities {
        ${additionalCreditLiability}
      }

      actions {
        id
        createdAt
        type
      }
      actualCurrentHomeValue
      actualRenovationHomeValue
      appraisalCost
      appraisalManagementCompany
      appraisalStatus
      appraiserName
      appraiserReportSummary
      arvCltvRatio
      b2b2cReferral
      borrower {
        id
        email
        firstName
        lastName
        phoneNumber
      }
      borrowersAndPropertyInformation {
        ${borrowersAndPropertyInformation}
      }
      borrowersOnLoan
      renofiAdvisor {
        ${projectUser}
      }
      city
      contractReviewStatus
      createdAt
      currentMortgageBalance
      currentMortgagePayment
      documents {
        id
        documentType
      }
      drawPeriod
      estimatedRenovationHomeValue
      renofiPayment
      exceptionRequests {
        id
      }
      feasibilityReviews {
        id
        documents {
          id
        }
        reviewer {
          accountCategories
          fullName
          id
          registrationCompletedAt
        }
        status
        createdAt
      }
      gcddReviewStatus
      incomeCommentaries {
          ${incomeCommentary}
      }
      interestRateType
      invitationSentAt
      lender {
        ${lender}
      }
      lenderId
      loanOfficerAssistant {
        ${projectUser}
      }
      raas
      fastTracked
      loanPassPricingUrl
      loanValue
      loanType
      loanTypeEnum {
        ${loanTypeValue}
      }
      loanTypeRequiresBeingOnTitle
      leadScenarioId
      matchedToLenderCrmId
      matchedToLenderDetails
      mortgageStatementDate
      narrative
      opportunity {
        ${opportunity}
      }
      peakCltvRatio
      positionedInterestRate
      qualifyingPayment
      renofiId
      renovationCostToCurrentPropertyValueRatio
      renovationCost
      ruStatus
      ruSummary
      renovationScope
      renovationReadyFile {
        id
        lenderId
        lender {
          id
          friendlyId
          name
        }
        transferredAt
        transmittedById
        applicationProcess
      }
      renovationReadyFiles {
        id
        transferredAt
        loanApplicationStatus
        loanApplicationStatusSetAt
      }
      renovationUnderwritingRequest {
        assets
        cancelable
        city
        createdAt
        createdById
        creditScore
        currentHomeValue
        currentMortgageBalance
        dtiRatio
        email
        firstName
        id
        intendedStartDate
        lastName
        lenderAccountExecutive {
          email
          firstName
          lastName
          phoneNumber
        }
        lenderId
        lender {
          id
          friendlyId
          name
        }
        lenderLoanNumber
        loanValue
        mloEmail
        mloFirstName
        mloLastName
        mloPhone
        phoneNumber
        postRenovationHomeValue
        renovationCost
        reserves
        returnDeadline
        state
        status
        streetAddressOne
        updatedAt
        zipCode
      }
      repaymentPeriod
      renovationUnderwritingRequestId
      productDetailsConfirmationStatus
      secondMortgageBalance
      selfReportedAssets
      selfReportedCreditScoreRange
      selfReportedDtiRatio
      allSelfReportedMonthlyDebt
      calculatedSelfReportedDtiRatio
      selfReportedIncome
      salesforceOpportunityId
      stageName
      stageTitle
      statedCurrentHomeValue
      state
      status
      streetAddressOne
      streetAddressTwo
      owner {
        ${projectUser}
      }
      projectAssociate {
        ${projectUser}
      }
      projectUsers {
        ${projectUser}
      }
      postRenovationHomeValue
      tasks {
        additionalDocumentRequest {
          ${additionalDocumentRequest}
        }
        allDocumentsAccepted
        allDocumentsReviewed
        appraisalPropertyPhotos {
          id
        }
        assetDetails {
          ${assetDetails}
        }
        canHaveDocument
        completedAt
        contractorInformations {
          ${contractor}
          duplicateOfContractor {
            id
            businessName
            state
            website
            zipCode
          }
          gcddReview {
            assignedAt
            assignedTo {
              email
              id
              firstName
              lastName
            }
            contractor {
              id
            }
            questionnaire {
              createdAt
              id
              lastSentAt
              returnedAt
              reviewedAt
            }
            id
          }
        }
        documents {
          id
          documentType
          fileName
          objectName
          status
        }
        employmentHistories {
          adjustedGrossMonthlyIncomeBase
          adjustedGrossMonthlyIncomeBonus
          adjustedGrossMonthlyIncomeCommission
          adjustedGrossMonthlyIncomeMilitaryEntitlements
          adjustedGrossMonthlyIncomeOther
          adjustedGrossMonthlyIncomeOvertime
          ${employmentHistory}
        }
        facet
        id
        incomeSources {
          ${incomeSource}
        }
        informationKind
        status
        taskType
        title
        productDetailsConfirmation {
          ${productDetailsConfirmation}
        }
        advisorFinalCallSchedule {
          ${advisorFinalCallSchedule}
        }
        certificateAcknowledgement {
          ${certificateAcknowledgement}
        }
        identityVerifications {
          borrowerRole
          status
        }
        personalInfoAndResidency {
          ${personalInfoAndResidency}
        }
        realEstateOwned {
          additionalProperties {
            id
          }
          soldProperties {
            id
          }
          subjectProperty {
            id
          }
        }
        softCreditCheck {
          ${softCreditCheck}
        }
      }
      tasksOverview {
        documentsUploaded
        lastDocumentUploadedAt
        outstandingNoDocuments
        outstandingTotal
        withRejectedDocuments
        withDocumentsInReview
      }
      timeline {
        id
        appraisalReportReviewFinishedAt
        appraisalCompletedAt
        appraisalRequestedAt
        contractorsReadyForContactAt
        invitationAcceptedAt
        invitationSentAt
        readyFor1003PrepOn
        tpoRegistrationCompletedAt,
        x1003CompleteOn
        x1003PrepCompleteOn
        loanClosedOn
        renovationStartedOn
        renovationCompletedOn
        stageLastChangedAt
      }
      titleHolders
      updatedAt
      underwriter {
        ${projectUser}
      }
      underwritingConditions {
        ${underwritingCondition}
      }
      yearsInHome
      zipCode
      wholesale
    }
  }
`;
