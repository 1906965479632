import useToggledQuery from '../../useToggledQuery';
import {GET_LOAN_PRODUCTS} from '../../queries';

export default function useLoanProducts({
  fetchPolicy = 'cache-first',
  lazy = false,
  ...options
} = {}) {
  const response = useToggledQuery(GET_LOAN_PRODUCTS, {
    lazy,
    fetchPolicy,
    ...options,
  });

  const {data, error, loading, fetch} = response;
  const loanProducts = data?.loanProducts || [];

  return {error, fetch, loanProducts, loading};
}
