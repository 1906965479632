import {pathOr} from 'ramda';

import {noop} from '@renofi/utilities';

import useMutation from '../useMutation';
import {REMOVE_DOCUMENTS} from '../../mutations';

export default function useRemoveDocuments({
  onCompleted = noop,
  refetchQueries = [],
} = {}) {
  const [removeDocuments, {data, loading, error}] = useMutation(
    REMOVE_DOCUMENTS,
    {
      onCompleted,
      refetchQueries,
    },
  );
  const response = pathOr(null, ['removeDocuments'], data);
  return {removeDocuments, response, loading, error};
}
