import React, {Fragment, useCallback, useEffect, useState} from 'react';

import PropTypes from 'prop-types';
import {assoc, pathOr} from 'ramda';

import {useSignedUrl} from '@renofi/api';
import {getFileType, getObjectName} from '@renofi/utilities';
import {openTab} from '@renofi/utilities/src/window';

import ImageContainer from '../ImageContainer';
import {INITIAL_ANGLE, INITIAL_SCALE, PAGE_WIDTH} from '../constants';
import Container from '../Container';
import ViewerControls from '../ViewerControls';

import AdobePdfViewer from './components/AdobePdfViewer';

const WHEEL_EVENT_OPTIONS = {
  capture: true,
  passive: false,
};

const Viewer = ({
  document,
  hasControls = false,
  projectId,
  width = PAGE_WIDTH,
}) => {
  const [angles, setAngles] = useState({});
  const [scales, setScales] = useState({});
  const [current, setCurrent] = useState(1);
  const [pages, setPages] = useState(1);

  const {fetch} = useSignedUrl({lazy: true});
  const {fileName} = document;
  const objectName = getObjectName(document);

  const {image, iosImage, office, pdf} = getFileType(fileName);
  const isPdfDoc = pdf || iosImage || office;
  const isImageDoc = !!image;

  const id = document?.id;
  const angle = id ? (angles[id] ?? INITIAL_ANGLE) : INITIAL_ANGLE;
  const scale = id ? (scales[id] ?? INITIAL_SCALE) : INITIAL_SCALE;

  const canShowControls = hasControls && isImageDoc;
  // if using Adobe viewer, disable controls 'cos we can't access their API.

  const onChangeAngle = useCallback(() => {
    setAngles(assoc(id, angle === -270 ? 0 : angle - 90, angles));
  }, [angles, angle, id]);

  const onChangeScale = useCallback(
    (value) => {
      if (!(value >= 0.1 && value <= 2)) {
        return;
      }
      setScales(assoc(id, value, scales));
    },
    [id, scales],
  );

  const onDownload = useCallback(async () => {
    const rsp = await fetch({variables: {objectName}});
    const url = pathOr(null, ['data', 'signedUrl', 'url'], rsp);

    if (url) {
      openTab(url);
    }
  }, [objectName]);

  const wheelHandler = useCallback(
    (event) => {
      if (!event.ctrlKey) return;
      event.preventDefault();
      const level = scale + (event.deltaY < 0 ? 0.05 : -0.05);
      onChangeScale(level);
    },
    [id, scale],
  );

  useEffect(() => {
    window.addEventListener('wheel', wheelHandler, WHEEL_EVENT_OPTIONS);
    return () =>
      window.removeEventListener('wheel', wheelHandler, WHEEL_EVENT_OPTIONS);
  }, [wheelHandler]);

  return (
    <Fragment>
      {isImageDoc && (
        <ImageContainer
          angle={angle}
          objectName={objectName}
          scale={scale}
          width={width}
        />
      )}
      {isPdfDoc && (
        <AdobePdfViewer
          angle={angle}
          pdf={document}
          fileName={fileName}
          objectName={objectName}
          onChangePage={setCurrent}
          onLoadComplete={setPages}
          projectId={projectId}
          scale={scale}
          width={width}
        />
      )}
      {!isImageDoc && !isPdfDoc && (
        <Container>Unsupported file format.</Container>
      )}
      {canShowControls && (
        <ViewerControls
          angle={angle}
          changeAngle={onChangeAngle}
          changeScale={onChangeScale}
          current={current}
          handleDownload={onDownload}
          pages={pages}
          scale={scale}
          width={width}
        />
      )}
    </Fragment>
  );
};

Viewer.propTypes = {
  projectId: PropTypes.string,
  document: PropTypes.shape({
    createdAt: PropTypes.string,
    fileName: PropTypes.string,
    id: PropTypes.string.isRequired,
    objectName: PropTypes.string,
    pdfObjectName: PropTypes.string,
  }),
  hasControls: PropTypes.bool,
  width: PropTypes.number,
};

export default Viewer;
