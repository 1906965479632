import {Flex, TaskDetailsCard} from '@renofi/components-internal';
import formatMoney from '@renofi/utilities/src/formatMoney';
import getYesNoFromBool from '@renofi/utilities/src/getYesNoFromBool';
import {useEnums} from '@renofi/graphql';

import {Label, Value} from '../styled';

import {getByValue} from './utils';

const SubjectPropertyDetails = ({internal, onClickValue, property}) => {
  const {enums} = useEnums();

  const {
    realEstateOwnedPropertyOccupancyTypes = [],
    realEstateOwnedPropertyTypeTypes = [],
  } = enums || {};

  const {
    existingMortgageLoan,
    homeownerMonthlyInsurance,
    homeownersAssociationFee,
    mortgageBalance,
    mortgageMonthlyInsurance,
    mortgageMonthlyPayment,
    mortgageCompany,
    otherMonthlyExpenses,
    propertyMonthlyTaxes,
    propertyStructureType,
    propertyUseType,
  } = property || {};

  return (
    <TaskDetailsCard>
      <Flex flexWrap="wrap">
        <Label>Outstanding mortgage:</Label>
        <Value onClick={onClickValue}>
          {getYesNoFromBool(existingMortgageLoan)}
        </Value>

        <Label>Mortgage balance:</Label>
        <Value onClick={onClickValue}>{formatMoney(mortgageBalance)}</Value>

        <Label>Mortgage monthly payment:</Label>
        <Value onClick={onClickValue}>
          {formatMoney(mortgageMonthlyPayment)}
        </Value>

        {internal ? (
          <>
            <Label>Mortgage company</Label>
            <Value onClick={onClickValue}>{mortgageCompany || '-'}</Value>
          </>
        ) : null}

        <Label>Property taxes</Label>
        <Value onClick={onClickValue}>
          {formatMoney(propertyMonthlyTaxes)}
        </Value>

        <Label>Homeowner insurance</Label>
        <Value>{formatMoney(homeownerMonthlyInsurance)}</Value>

        <Label>HOA monthly fees</Label>
        <Value>{formatMoney(homeownersAssociationFee)}</Value>

        <Label>Mortgage insurance</Label>
        <Value>{formatMoney(mortgageMonthlyInsurance)}</Value>

        <Label>Other expenses</Label>
        <Value>{formatMoney(otherMonthlyExpenses)}</Value>

        <Label>Property type</Label>
        <Value onClick={onClickValue}>
          {getByValue(realEstateOwnedPropertyTypeTypes)(propertyStructureType)}
        </Value>

        <Label>Current occupancy</Label>
        <Value onClick={onClickValue}>
          {getByValue(realEstateOwnedPropertyOccupancyTypes)(propertyUseType)}
        </Value>
      </Flex>
    </TaskDetailsCard>
  );
};

export default SubjectPropertyDetails;
