import {propOr} from 'ramda';

import {noop} from '@renofi/utilities';

import useMutation from '../../useMutation';
import {SIGN_QUESTIONNAIRE_ON_CONTRACTOR_BEHALF} from '../../../mutations';

export default function useSignQuestionnaireOnContractorBehalf({
  onCompleted = noop,
  refetchQueries = [],
} = {}) {
  const [signQuestionnaireOnContractorBehalf, {data, loading, error}] =
    useMutation(SIGN_QUESTIONNAIRE_ON_CONTRACTOR_BEHALF, {
      onCompleted,
      refetchQueries,
    });
  const response = propOr(null, 'signQuestionnaireOnContractorBehalf', data);
  return {signQuestionnaireOnContractorBehalf, response, loading, error};
}
