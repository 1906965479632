import {noop} from '@renofi/utilities';

import {GET_FEASIBILITY_REVIEWERS} from '../../queries';
import useLazyQuery from '../useLazyQuery';

export default function useGetFeasibilityReviewers({onCompleted = noop} = {}) {
  const [getFeasibilityReviewers, {data, loading, error}] = useLazyQuery(
    GET_FEASIBILITY_REVIEWERS,
    {
      onCompleted,
    },
  );

  return {
    getFeasibilityReviewers,
    feasibilityReviewers: data?.feasibilityReviewers,
    loading,
    error,
  };
}
