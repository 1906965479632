import {pathOr} from 'ramda';

import {noop} from '@renofi/utilities';

import useMutation from '../useMutation';
import {TRANSMIT_CONFIRMED_PRODUCT_TO_LENDER} from '../../mutations';

export default function useTransmitConfirmedProductToLender({
  onCompleted = noop,
  refetchQueries = [],
} = {}) {
  const [transmitConfirmedProductToLender, {data, loading, error}] =
    useMutation(TRANSMIT_CONFIRMED_PRODUCT_TO_LENDER, {
      onCompleted,
      refetchQueries,
    });
  const response = pathOr(null, ['transmitConfirmedProductToLender'], data);
  return {transmitConfirmedProductToLender, response, loading, error};
}
