import {basic20} from '@renofi/theme';

const Gauge = ({color = basic20, width = 20}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={width}
      viewBox="0 0 20 20"
      fill="none">
      <path
        d="M13.1183 10.3437L9.26879 11.9129C9.05024 12.0093 8.85598 12.1539 8.686 12.3708C8.12749 13.0457 8.24891 14.0579 8.92883 14.6122C9.60875 15.1665 10.6286 15.046 11.1871 14.3712L13.7497 11.1631C13.8711 11.0185 13.8954 10.8016 13.8225 10.6329C13.6768 10.3437 13.3854 10.2232 13.1183 10.3437Z"
        fill={color}
      />
      <path
        d="M2.49274 10.7534C2.0545 10.5004 1.4899 10.6775 1.32586 11.1562C1.09255 11.8371 0.937145 12.5541 0.870283 13.2966C0.829008 13.755 1.20607 14.1293 1.6663 14.1293C2.12654 14.1293 2.49399 13.7543 2.55028 13.2976C2.60927 12.8189 2.7192 12.356 2.87704 11.9144C3.03149 11.4823 2.89017 10.9829 2.49274 10.7534Z"
        fill={color}
      />
      <path
        d="M3.19733 7.98456C2.8026 8.42127 2.95244 9.09434 3.46225 9.38868C3.86846 9.62321 4.37851 9.5375 4.73563 9.23339C4.82472 9.15752 4.91623 9.08369 5.01012 9.01198C5.45494 8.67223 5.63346 8.05801 5.3536 7.57327L5.32833 7.52951C5.03598 7.02313 4.36942 6.87146 3.93132 7.25869C3.6733 7.48676 3.42822 7.72913 3.19733 7.98456Z"
        fill={color}
      />
      <path
        d="M7.26884 5.37628C6.70387 5.55235 6.49287 6.21322 6.78876 6.72571L7.02527 7.13535C7.25554 7.53419 7.72757 7.71834 8.17977 7.63115C8.20442 7.62639 8.22913 7.62174 8.25391 7.61718C8.76567 7.52303 9.16634 7.09614 9.16634 6.5758V6.09416C9.16634 5.50645 8.65989 5.04073 8.08501 5.16289C7.80813 5.22172 7.53586 5.29306 7.26884 5.37628Z"
        fill={color}
      />
      <path
        d="M10.833 6.63878C10.833 7.14073 11.209 7.55476 11.6909 7.69513C11.7066 7.69971 11.7223 7.70435 11.738 7.70906C12.2473 7.86238 12.8261 7.70034 13.0921 7.23968L13.3607 6.77443C13.6532 6.2678 13.4509 5.61426 12.8958 5.42956C12.5761 5.32318 12.2486 5.23394 11.9143 5.16291C11.3395 5.04074 10.833 5.50646 10.833 6.09418V6.63878Z"
        fill={color}
      />
      <path
        d="M15.7954 13.2966C15.7775 13.1016 15.7534 12.9093 15.7231 12.72C15.652 12.2762 15.8374 11.8201 16.2266 11.5953L17.4026 10.9164C17.915 10.6205 18.5758 10.8314 18.752 11.3963C18.9418 12.0049 19.0699 12.6408 19.129 13.2966C19.1703 13.755 18.7932 14.1293 18.333 14.1293H16.6663C16.2061 14.1293 15.8373 13.7549 15.7954 13.2966Z"
        fill={color}
      />
      <path
        d="M16.8687 8.05923C17.2561 8.4973 17.1045 9.164 16.598 9.4564L15.7946 9.92028C15.337 10.1844 14.754 10.0058 14.433 9.58616C14.1768 9.25128 14.1158 8.79991 14.3266 8.43475L14.7884 7.63494C15.0841 7.12275 15.7613 6.97492 16.1969 7.37488C16.4325 7.59116 16.6568 7.81961 16.8687 8.05923Z"
        fill={color}
      />
    </svg>
  );
};

export default Gauge;
