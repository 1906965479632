import {useState} from 'react';

import * as uuid from 'uuid';
import {find, pathOr, pipe, prop, propEq, propOr, reject} from 'ramda';
import {useApolloClient} from '@apollo/client';

import {GET_SIGNED_URLS, GET_UPLOAD_METADATA} from '@renofi/api';
import {uploadFile} from '@renofi/utilities/src/cloud';
import validateFiles from '@renofi/utilities/src/validateFiles';
import logger from '@renofi/utilities/src/logger';
import {taskDocumentStatuses} from '@renofi/utilities/src/enums';

const checkSignal = (signal) => {
  if (signal.aborted) {
    throw new Error('File upload cancelled');
  }
};

const getValidatedFiles = async ({
  files: selectedFiles = [],
  checksums,
  validateEncryption = false,
}) => {
  const files = selectedFiles.map((file) => ({
    controller: new AbortController(),
    id: uuid.v4(),
    file,
  }));

  return validateFiles({files, checksums, validateEncryption});
};

function useUploadFiles({
  basePath = 'documents',
  validateEncryption = false,
} = {}) {
  const client = useApolloClient();
  const [uploading, setUploading] = useState([]);
  const isUploading = Boolean(uploading?.length);

  const cancelUpload = (targetId) => {
    const pendingUpload = find(propEq('id', targetId), uploading);
    const controller = propOr(null, 'controller', pendingUpload);
    if (controller) {
      controller.abort();
      setUploading(reject(propEq('id', targetId), uploading));
    }
  };

  const uploadFiles = async (files = []) => {
    const checksums = [];
    const [acceptedFiles] = await getValidatedFiles({
      files,
      checksums,
      validateEncryption,
    });

    logger.debug('acceptedFiles', acceptedFiles);
    setUploading(acceptedFiles);

    const uploaded = await Promise.all(
      acceptedFiles.map(async (acceptedFile) => {
        const {checksum, controller, file, id, isEncrypted} = acceptedFile;
        const {signal} = controller;
        const createdAt = new Date().toISOString();
        const contentType = prop('type', file);
        const size = prop('size', file);
        // Get the upload metadata...
        try {
          checkSignal(signal);
          const rsp = await client.query({
            query: GET_UPLOAD_METADATA,
            context: {
              fetchOptions: {
                signal,
              },
            },
            variables: {
              fileName: file?.name,
              contentType,
              basePath,
              recordId: id,
            },
          });
          const uploadedFile = pathOr(null, ['data', 'uploadMetadata'], rsp);
          const url = propOr(null, 'url', uploadedFile);

          // Then actually upload the file itself
          checkSignal(signal);
          await uploadFile(url, file, {signal});
          return {
            checksum,
            contentType,
            controller,
            createdAt,
            id,
            isEncrypted,
            size,
            status: taskDocumentStatuses.processingFile,
            ...uploadedFile,
          };
        } catch (err) {
          logger.error(err);
          return null;
        }
      }),
    );

    // Finally get the signed URLs for each file.
    const signedQuery = await client.query({
      query: GET_SIGNED_URLS,
      variables: {
        objectNames: uploaded.map(prop('objectName')),
      },
    });
    const signedUrls = pathOr(null, ['data', 'signedUrls'], signedQuery);

    const finalFiles = uploaded.reduce((arr, {objectName, ...file}) => {
      return arr.concat({
        objectName,
        ...file,
        url: pipe(
          find(propEq('objectName', objectName)),
          propOr(null, 'url'),
        )(signedUrls),
      });
    }, []);

    setUploading([]);
    logger.debug('finalFiles', finalFiles);
    return finalFiles;
  };

  return {cancelUpload, isUploading, uploading, uploadFiles};
}

export default useUploadFiles;
