import {gql} from '@apollo/client';

export default gql`
  mutation toggleRenovationUpdateRemindersForProject(
    $disabled: Boolean!
    $projectId: ID!
  ) {
    toggleRenovationUpdateRemindersForProject(
      disabled: $disabled
      projectId: $projectId
    ) {
      project {
        id
        renovationUpdateRemindersDisabledAt
      }
    }
  }
`;
