import {pathOr} from 'ramda';

import {noop} from '@renofi/utilities';

import {SUBMIT_INSURANCE_REVIEW} from '../mutations';

import useMutation from './useMutation';

export default function useSubmitInsuranceReview({onCompleted = noop} = {}) {
  const [submitInsuranceReview, {data, loading, error}] = useMutation(
    SUBMIT_INSURANCE_REVIEW,
    {
      onCompleted,
    },
  );
  const response = pathOr(null, ['insuranceReview'], data);

  return {submitInsuranceReview, response, loading, error};
}
