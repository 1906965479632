import {Flex, TaskDetailsCard} from '@renofi/components-internal';
import formatMoney from '@renofi/utilities/src/formatMoney';
import {useEnums} from '@renofi/graphql';
import {getFullName} from '@renofi/utilities/src/data';
import getYesNoFromBool from '@renofi/utilities/src/getYesNoFromBool';

import {Label, Value} from '../styled';

import {getByValue} from './utils';

const AdditionalPropertyDetails = ({
  borrower,
  coborrower,
  internal = false,
  onClickValue,
  property,
}) => {
  const {enums} = useEnums();
  const {
    realEstateOwnedPropertyOccupancyTypes = [],
    realEstateOwnedPropertyStatusTypes = [],
    realEstateOwnedPropertyTypeTypes = [],
  } = enums || {};
  const borrowerName = getFullName(borrower);
  const coborrowerName = getFullName(coborrower);
  const {
    coborrowerOwned,
    existingMortgageLoan,
    homeownerMonthlyInsurance,
    homeownersAssociationFee,
    marketValue,
    mortgageBalance,
    mortgageMonthlyInsurance,
    mortgageMonthlyPayment,
    mortgageCompany,
    otherMonthlyExpenses,
    primaryBorrowerOwned,
    propertyMonthlyTaxes,
    propertyStatus,
    propertyStructureType,
    propertyUseType,
    rentalIncome,
    yearPurchased,
  } = property || {};

  return (
    <TaskDetailsCard>
      <Flex flexWrap="wrap" alignItems="center">
        <Label>Outstanding mortgage</Label>
        <Value onClick={onClickValue}>
          {getYesNoFromBool(existingMortgageLoan)}
        </Value>

        <Label>Property taxes</Label>
        <Value onClick={onClickValue}>
          {formatMoney(propertyMonthlyTaxes)}
        </Value>

        <Label>Homeowner insurance</Label>
        <Value>{formatMoney(homeownerMonthlyInsurance)}</Value>

        <Label>HOA monthly fees</Label>
        <Value>{formatMoney(homeownersAssociationFee)}</Value>

        <Label>Mortgage insurance</Label>
        <Value>{formatMoney(mortgageMonthlyInsurance)}</Value>

        <Label>Other expenses</Label>
        <Value>{formatMoney(otherMonthlyExpenses)}</Value>

        <Label>Property type</Label>
        <Value onClick={onClickValue}>
          {getByValue(realEstateOwnedPropertyTypeTypes)(propertyStructureType)}
        </Value>

        <Label>Current occupancy</Label>
        <Value onClick={onClickValue}>
          {getByValue(realEstateOwnedPropertyOccupancyTypes)(propertyUseType)}
        </Value>

        <Label>Status of property</Label>
        <Value onClick={onClickValue}>
          {getByValue(realEstateOwnedPropertyStatusTypes)(propertyStatus)}
        </Value>
        {rentalIncome ? (
          <>
            <Label>Rental income</Label>
            <Value onClick={onClickValue}>
              {formatMoney(rentalIncome, {
                fractional: false,
              })}{' '}
              / month
            </Value>
          </>
        ) : null}

        <Label>Current market value</Label>
        <Value onClick={onClickValue}>
          {formatMoney(marketValue, {
            fractional: false,
          })}
        </Value>

        <Label>Year purchased</Label>
        <Value skipComma={!coborrowerOwned}>{yearPurchased}</Value>

        {coborrowerOwned || primaryBorrowerOwned ? (
          <>
            <Label>Ownership</Label>
            <Value onClick={onClickValue}>
              {primaryBorrowerOwned ? borrowerName : ''}
              {primaryBorrowerOwned && coborrowerOwned ? ', ' : ''}
              {coborrowerOwned ? coborrowerName : ''}
            </Value>
          </>
        ) : null}

        {internal ? (
          <>
            <Label>Mortgage balance</Label>
            <Value onClick={onClickValue}>{formatMoney(mortgageBalance)}</Value>

            <Label>Monthly mortgage payment</Label>
            <Value onClick={onClickValue}>
              {formatMoney(mortgageMonthlyPayment, {
                fractional: false,
              })}
            </Value>

            <Label>Mortgage company</Label>
            <Value onClick={onClickValue}>{mortgageCompany}</Value>
          </>
        ) : null}
      </Flex>
    </TaskDetailsCard>
  );
};

export default AdditionalPropertyDetails;
