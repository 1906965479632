import {isValid, parse} from 'date-fns';
import {isObject} from 'lodash';

import {isEmail} from '@renofi/utils';
import {isPhone} from '@renofi/utilities';
import calculateAge from '@renofi/utilities/src/calculateAge';

export function validate(item, value) {
  let error;
  if (item === 'required' && !value && value !== 0) {
    error = 'This is required';
  }
  if (item !== 'required' && (value === '' || value === 'MM/DD/YYYY')) {
    return;
  }
  if (item === 'email' && !isEmail(value)) {
    error = 'Invalid email';
  }
  if (item === 'phone' && !isPhone(value)) {
    error = 'Invalid phone';
  }
  if (
    item === 'date' &&
    !value.includes('_') &&
    !isValid(parse(value, 'MM/dd/yyyy', new Date()))
  ) {
    error = 'Invalid date';
  }
  if (item === 'age' && !value.includes('_')) {
    const age = calculateAge(value);
    const isValid = age && age >= 18 && age < 120;

    if (!isValid) error = 'Invalid age';
  }
  if (isObject(item)) {
    for (const [key, validationValue] of Object.entries(item)) {
      if (key === 'date' && !value.includes('_')) {
        const age = calculateAge(value);
        if (!isValid(parse(value, 'MM/dd/yyyy', new Date()))) {
          error = 'Invalid date';
        }

        if (age && -age < validationValue.from) {
          error = 'Date is too old';
        }
        if (age && -age > validationValue.to) {
          error = 'Date is too recent';
        }
      }

      if (key === 'invalidCharacter' && value.includes(validationValue)) {
        error = `${validationValue} is not allowed`;
      }

      if (key === 'custom' && validationValue) {
        error = validationValue;
      }
    }
  }
  return error;
}
