import React, {useState, useRef} from 'react';

import PropTypes from 'prop-types';

import {Pencil, PlusCircle} from '@renofi/icons';

import Tooltip from '../Tooltip';
import {SelectOption} from '../SelectField';
import ClickOutside from '../ClickOutside';
import IconButton from '../IconButton';
import Box from '../Box';

import {
  MainText,
  SubText,
  TabsContainer,
  Tab,
  ButtonWrapper,
  OptionsWrapper,
} from './styled';

const TabsComponent = ({
  items,
  tip,
  onClickTab,
  showAddButton,
  onAddClick,
  showEditButton,
  editItems,
  onEditItemClick,
  selected,
  suffix,
}) => {
  const [showEditMenu, setShowEditMenu] = useState(false);
  const ref = useRef(null);

  function onEdit() {
    setShowEditMenu(!showEditMenu);
  }

  const handleTabClick = (value) => {
    onClickTab(value);
  };

  return (
    <TabsContainer>
      {items.map((item) => {
        const isActive = selected === item.value;
        return (
          <Tab
            key={item.value}
            isActive={isActive}
            onClick={() => handleTabClick(item.value)}>
            <MainText bold={isActive || item.error} error={item.error}>
              {item.label}
            </MainText>
            {item.subText && <SubText>{item.subText}</SubText>}
          </Tab>
        );
      })}

      {showAddButton ? (
        <ButtonWrapper>
          <Tooltip content={tip} place="top" white height={24}>
            <IconButton variant="transparent" onClick={onAddClick}>
              <PlusCircle width={24} />
            </IconButton>
          </Tooltip>
        </ButtonWrapper>
      ) : null}

      {showEditButton ? (
        <div ref={ref}>
          <ClickOutside ref={ref} onClickOutside={() => setShowEditMenu(false)}>
            <ButtonWrapper>
              <Tooltip
                height={24}
                content={showEditMenu ? undefined : tip}
                place="top"
                white>
                <IconButton
                  variant="transparent"
                  onClick={onEdit}
                  frameHover
                  selected={showEditMenu}>
                  <Pencil width={18} />
                </IconButton>
              </Tooltip>

              {showEditMenu ? (
                <OptionsWrapper>
                  {editItems.map(({label, value, disabled}) => (
                    <SelectOption
                      disabled={disabled}
                      key={label}
                      onClick={() => onEditItemClick(value)}
                      css={{paddingLeft: 8, paddingRight: 8}}>
                      {label}
                    </SelectOption>
                  ))}
                </OptionsWrapper>
              ) : null}
            </ButtonWrapper>
          </ClickOutside>
        </div>
      ) : null}
      {suffix ? <Box ml="auto">{suffix}</Box> : null}
    </TabsContainer>
  );
};

TabsComponent.propTypes = {
  selected: PropTypes.string,
  items: PropTypes.array,
  editItems: PropTypes.array,
  tip: PropTypes.string,
  onClickTab: PropTypes.func,
  showAddButton: PropTypes.bool,
  onAddClick: PropTypes.func,
  showEditButton: PropTypes.bool,
  onEditItemClick: PropTypes.func,
  suffix: PropTypes.node,
};

export default TabsComponent;
