import React, {useEffect, useState} from 'react';

import PropTypes from 'prop-types';
import {useParams} from 'react-router-dom';
import {find, findIndex, lensProp, prop, propEq, set} from 'ramda';
import {SCORE_BUTTON_OPTIONS} from 'lib/gcdd';
import {Container, StatusBadge} from 'modules/viewer-v2/components/Layout';
import Row from 'modules/viewer-v2/components/Row';
import Col from 'modules/viewer-v2/components/Col';

import {ButtonGroup, Text, Textarea} from '@renofi/components-internal';
import {
  GET_GCDD_REVIEWS_BY_PROJECT_ID,
  useSubmitReferenceScore,
  useSubmitReviewNotes,
} from '@renofi/graphql';
import humanizeSnakeCase from '@renofi/utilities/src/humanizeSnakeCase';
import useDebounce from '@renofi/utilities/src/useDebounce';

import {getReferenceStatus} from './utils';

function References({gcddReview}) {
  const {projectId} = useParams();
  const refetchQueries = [
    {
      query: GET_GCDD_REVIEWS_BY_PROJECT_ID,
      variables: {projectId},
    },
  ];
  const {licenseNotes, insuranceNotes, webReviewsNotes} = gcddReview;
  const gcddReviewId = prop('id', gcddReview);

  const {submitReferenceScore} = useSubmitReferenceScore({refetchQueries});
  const {submitReviewNotes} = useSubmitReviewNotes({refetchQueries});
  const [references, setReferences] = useState([]);
  const [reviewNotes, setReviewNotes] = useState(
    gcddReview?.referencesNotes || '',
  );
  const [showComments, setShowComments] = useState(Boolean(reviewNotes));

  const {status, variant} = getReferenceStatus(references);

  useEffect(() => {
    setReferences(gcddReview?.questionnaire?.references || []);
  }, [gcddReview]);

  const onChangeScore = async (id, score) => {
    const reference = find(propEq('id', id), references);
    const index = findIndex(propEq('id', id), references);

    let newReferences = [...references];
    newReferences[index] = set(lensProp('score'), score, reference);
    setReferences(newReferences);

    await submitReferenceScore({variables: {id, score}});
  };

  const debouncedSubmitReviewNotes = useDebounce(async (variables) => {
    await submitReviewNotes({variables});
  }, 1000);

  const onChangeNotes = (value) => {
    setReviewNotes(value);
    debouncedSubmitReviewNotes({
      gcddReviewId,
      licenseNotes,
      insuranceNotes,
      referencesNotes: value,
      webReviewsNotes,
    });
  };

  return (
    <Container
      header={
        <Row alignItems="center">
          <Text mr={2}>References</Text>
          <StatusBadge variant={variant}>{status}</StatusBadge>
        </Row>
      }
      collapsible={false}
      comment
      toggleCommentSection={() => setShowComments(!showComments)}>
      {showComments && (
        <Row>
          <Col width={1}>
            <Textarea
              label="Add a section comment"
              help="Comments are visible to the lender"
              rows={4}
              value={reviewNotes}
              onChange={onChangeNotes}
            />
          </Col>
        </Row>
      )}
      {references.map((reference) => {
        const {id, name, score, type} = reference;
        const label = `${name} (${humanizeSnakeCase(type)})`;

        return (
          <Row key={id}>
            <Col width={1}>
              <ButtonGroup
                active
                label={label}
                onChange={(value) => onChangeScore(id, value)}
                options={SCORE_BUTTON_OPTIONS}
                value={score}
              />
            </Col>
          </Row>
        );
      })}
    </Container>
  );
}

References.propTypes = {
  gcddReview: PropTypes.shape({
    questionnaire: PropTypes.shape({
      references: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
        }),
      ),
    }),
    licenseNotes: PropTypes.string,
    insuranceNotes: PropTypes.string,
    webReviewsNotes: PropTypes.string,
  }),
};

export default References;
