import {noop} from '@renofi/utilities';

import useMutation from '../../useMutation';
import {SUBMIT_OVERALL_SCORE} from '../../../mutations';

export default function useSubmitOverallScore({
  onCompleted = noop,
  refetchQueries = [],
} = {}) {
  const [submitOverallScore, {abort, data, loading, error}] = useMutation(
    SUBMIT_OVERALL_SCORE,
    {
      onCompleted,
      refetchQueries,
    },
  );
  const response = data?.submitOverallScore?.gcddReview || null;

  return {abort, submitOverallScore, response, loading, error};
}
