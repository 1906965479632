import {pathOr} from 'ramda';

import {noop} from '@renofi/utilities';

import useMutation from '../useMutation';
import {SET_DOCUMENT_TYPE} from '../../mutations';

export default function useSetDocumentType({
  onCompleted = noop,
  refetchQueries = [],
} = {}) {
  const [setDocumentType, {data, loading, error}] = useMutation(
    SET_DOCUMENT_TYPE,
    {
      onCompleted,
      refetchQueries,
    },
  );
  const response = pathOr(null, ['setDocumentType'], data);
  return {setDocumentType, response, loading, error};
}
