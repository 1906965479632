import React, {Fragment, useMemo} from 'react';

import PropTypes from 'prop-types';
import {groupBy, keys, isEmpty} from 'ramda';

import {Flex, TaskDetailsCard, Text} from '@renofi/components-internal';
import {useEnums} from '@renofi/graphql';
import {basic80} from '@renofi/theme';
import formatMoney from '@renofi/utilities/src/formatMoney';
import {filterItemsByBorrowerRole} from '@renofi/utilities';
import {noop} from '@renofi/utilities';

import TaskDocument from '../../../TaskDocument';
import {
  NO_INCOME_TYPE,
  OTHER_INCOME_TYPE,
  RENTAL_INCOME_TYPE,
  taskStatuses,
} from '../../../../constants';
import {Label, Value} from '../../../styled';
import {getAddedPrefixByDocumentType} from '../../../../utils';

import {filterNoIncome} from './utils';
import {DocumentCard, TotalIncome} from './styled';

const showMoney = (value) => formatMoney(value, {fractional: false});

const IncomeSourceSummary = ({
  areActionsDisabled = true,
  borrowerRole,
  incomeSources = [],
  onClickDocument = noop,
  internal = false,
  onAccept = noop,
  onReject = noop,
  status,
}) => {
  const {documentTypes, incomeTypes} = useEnums();
  const isCompleted = status === taskStatuses.COMPLETED;
  const filteredSources = useMemo(() => {
    let result = filterItemsByBorrowerRole(incomeSources, {
      borrowerRole,
    });

    if (borrowerRole === 'coborrower') {
      result = filterNoIncome(result);
    }

    return result;
  }, [incomeSources, borrowerRole]);

  const hasSources = !isEmpty(filteredSources);

  const groupedDocuments = useMemo(() => {
    const filteredDocuments = incomeSources
      .filter((s) => s.borrowerRole === borrowerRole)
      .reduce((arr, i) => arr.concat(i?.documents || []), []);

    return groupBy(({documentType}) => documentType, filteredDocuments);
  }, [borrowerRole, JSON.stringify(incomeSources)]);

  const totalIncome = filteredSources.reduce(
    (total, s) => total + s.grossMonthlyIncome,
    0,
  );

  return (
    <Fragment>
      {hasSources && (
        <TaskDetailsCard success={isCompleted}>
          {filteredSources.map((incomeSource) => {
            const {
              grossMonthlyIncome,
              id,
              incomeType,
              incomeTypeOtherDetails,
              incomeTypeNoIncomeDetails,
              incomeTypeTotal,
            } = incomeSource || {};
            const isOther = incomeType === OTHER_INCOME_TYPE;
            const isRental = incomeType === RENTAL_INCOME_TYPE;
            const isNoIncome = incomeType === NO_INCOME_TYPE;
            const showNoIncomeDetails =
              isNoIncome && Boolean(incomeTypeNoIncomeDetails);
            const incomeTypeEnum = incomeTypes.find(
              (t) => t.value === incomeType,
            );
            const label = incomeTypeEnum?.label || incomeType;

            return (
              <Flex key={id}>
                <Label>Income source:</Label>
                <Value>
                  {label}
                  <span>,</span>
                </Value>
                {showNoIncomeDetails ? (
                  <Fragment>
                    <Label>Details:</Label>
                    <Value>{incomeTypeNoIncomeDetails}</Value>
                  </Fragment>
                ) : null}
                {isRental ? (
                  <Fragment>
                    <Label>Number of rental properties:</Label>
                    <Value>{incomeTypeTotal}</Value>
                  </Fragment>
                ) : null}
                {isOther ? (
                  <Fragment>
                    <Label>Other types of income:</Label>
                    <Value>{incomeTypeOtherDetails}</Value>
                  </Fragment>
                ) : null}

                {!isNoIncome ? (
                  <Fragment>
                    <Label>Gross average monthly income:</Label>
                    <Value>{showMoney(grossMonthlyIncome)}</Value>
                  </Fragment>
                ) : null}
              </Flex>
            );
          })}
          <TotalIncome>Total income: {showMoney(totalIncome)}</TotalIncome>
        </TaskDetailsCard>
      )}
      {keys(groupedDocuments).map((key) => {
        const documentTypeEnum = documentTypes.find((t) => t.value === key);
        const documents = groupedDocuments[key] || [];

        return (
          <Flex key={key} flexDirection="column" width={1}>
            <Text color={basic80} mb={2} mt={3} fontSize={16}>
              {documentTypeEnum?.label || key}
            </Text>

            <Flex width={1} flexWrap="wrap">
              {documents.map((doc) => {
                const nickname = getAddedPrefixByDocumentType(doc);
                const addedPrefix = `${nickname} added`.trim();
                const reviewedPrefix = `${nickname} reviewed`.trim();

                return (
                  <DocumentCard mb={2} internal={internal} key={doc.id}>
                    <TaskDocument
                      addedPrefix={addedPrefix}
                      areActionsDisabled={areActionsDisabled}
                      reviewedPrefix={reviewedPrefix}
                      onClick={() => onClickDocument(doc.id)}
                      showDownloadIcon={internal}
                      internal={internal}
                      onAccept={onAccept}
                      onReject={onReject}
                      immutable={!internal}
                      {...doc}
                    />
                  </DocumentCard>
                );
              })}
            </Flex>
          </Flex>
        );
      })}
    </Fragment>
  );
};

IncomeSourceSummary.propTypes = {
  areActionsDisabled: PropTypes.bool,
  internal: PropTypes.bool,
  onAccept: PropTypes.func,
  onReject: PropTypes.func,
  borrowerRole: PropTypes.string.isRequired,
  incomeSources: PropTypes.arrayOf(
    PropTypes.shape({
      borrowerRole: PropTypes.string,
      documents: PropTypes.arrayOf(
        PropTypes.shape({
          borrowerRole: PropTypes.string,
          id: PropTypes.string.isRequired,
        }),
      ),
      id: PropTypes.string.isRequired,
    }),
  ),
  onClickDocument: PropTypes.func,
  status: PropTypes.string,
};

export default IncomeSourceSummary;
