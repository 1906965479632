import React, {useCallback, useState} from 'react';

import PropTypes from 'prop-types';

import {noop} from '@renofi/utilities';
import useDebounce from '@renofi/utilities/src/useDebounce';

import Textarea from '../Textarea';
import Text from '../Text';

import {getTextColor, getHelpText} from './utils';

const AutoSaveTextarea = ({
  value,
  label,
  onChange = noop,
  onSave = noop,
  required = true,
  ...props
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const helpText = getHelpText({isSaving, required});
  const helpTextColor = getTextColor({isSaving, required, value});
  const debouncedSubmit = useDebounce(
    (newValue) => {
      onSubmit(newValue);
    },
    400,
    [isFocused],
  );

  const onChangeInput = useCallback(
    (newValue) => {
      onChange(newValue);
      debouncedSubmit(newValue);
    },
    [isFocused],
  );

  const onSubmit = useCallback(
    async (newValue) => {
      if (required && !newValue) {
        return;
      }

      if (isFocused) {
        setIsSaving(true);
        await onSave(newValue);
        setIsSaving(false);
      }
    },
    [isFocused],
  );

  return (
    <Textarea
      {...props}
      help={
        <Text fontSize={12} color={helpTextColor}>
          {helpText}
        </Text>
      }
      label={label}
      required={required}
      value={value}
      onChange={onChangeInput}
      onBlur={() => setIsFocused(false)}
      onFocus={() => setIsFocused(true)}
    />
  );
};

AutoSaveTextarea.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string,
  status: PropTypes.string,
  onChange: PropTypes.func,
  onSave: PropTypes.func,
  required: PropTypes.bool,
};

export default AutoSaveTextarea;
