import useToggledQuery from '../../useToggledQuery';
import {GET_APPRAISAL_ORDERS} from '../../queries';

export default function useAppraisalOrders({
  fetchPolicy = 'cache-first',
  lazy = false,
  ...options
}) {
  const response = useToggledQuery(GET_APPRAISAL_ORDERS, {
    lazy,
    fetchPolicy,
    ...options,
  });

  const {data, error, loading, fetch} = response;
  const appraisalOrders = data?.appraisalOrdersByProjectId || [];

  return {error, fetch, appraisalOrders, loading};
}
