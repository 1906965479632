import {gql} from '@apollo/client';

import {softCreditCheck} from '../../partials';

export default gql`
  mutation cloneSourceSoftCreditCheck($sourceSoftCreditCheckId: ID!) {
    cloneSourceSoftCreditCheck(sourceSoftCreditCheckId: $sourceSoftCreditCheckId) {
      softCreditCheck {
        ${softCreditCheck}
      }
    }

  }

`;
