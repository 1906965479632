import {identity} from 'ramda';

import {GET_APPRAISAL_ORDERS} from '@renofi/api';
import {compose, mapProps, withHandlers} from '@renofi/recompose';

import withApollo from './withApollo';
import withQuery from './withQuery';
import withProjectId from './withProjectId';

export default ({mapper = identity, options} = {}) =>
  compose(
    withProjectId(),
    withApollo,
    withQuery({
      query: GET_APPRAISAL_ORDERS,
      props: ({
        data: {previousData, appraisalOrdersByProjectId},
        ...props
      }) => ({
        appraisalOrders:
          appraisalOrdersByProjectId ||
          previousData?.appraisalOrdersByProjectId ||
          [],
        ...props,
      }),
      options:
        options ||
        (({projectId}) => ({
          variables: {projectId},
        })),
    }),
    withHandlers({
      getAppraisalOrders:
        ({client}) =>
        async ({projectId}) => {
          await client.query({
            query: GET_APPRAISAL_ORDERS,
            variables: {projectId},
            fetchPolicy: 'no-cache',
          });
        },
    }),
    mapProps(mapper),
  );
