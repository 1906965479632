import React, {
  Fragment,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import PropTypes from 'prop-types';
import {anyPass, is, toString, without} from 'ramda';

import {Context as ThemeContext} from '@renofi/theme';
import {noop} from '@renofi/utilities';

import Checkbox from '../Checkbox';
import Label from '../Label';
import TextField from '../TextField';

import {ListWrapper, Option, OptionsContainer} from './styled';

const isString = is(String);
const isNativeType = anyPass([is(Number), is(Boolean), isString]);

const SearchableList = ({
  error,
  help,
  isNullAllowed,
  label,
  leftIcon,
  name,
  onChange,
  options,
  optionsHeight,
  placeholder,
  value: currentValue,
}) => {
  const [searchInput, setSearchInput] = useState('');
  const [values, setValues] = useState(currentValue || []);
  const ref = useRef();
  const {theme} = useContext(ThemeContext);

  useEffect(() => {
    setValues(currentValue || []);
  }, [currentValue]);

  const finalOptions = useMemo(() => {
    const translatedOptions = options.map((opt) => {
      const isNative = isNativeType(opt);
      return isNative
        ? {
            label: isString(opt) ? opt : toString(opt),
            value: opt,
          }
        : opt;
    });
    return translatedOptions.filter((opt) => {
      const label = opt.label || opt.value;
      return label.toLowerCase().match(searchInput.toLowerCase());
    });
  }, [options, searchInput]);

  const onClickValue = (newValue, add) => {
    const updated = add
      ? values.concat([newValue])
      : without([newValue], values);
    const filtered = isNullAllowed ? updated : updated.filter(Boolean);
    setValues(filtered);
    onChange(filtered);
  };

  return (
    <Fragment>
      <Label labelFor={name} help={help} error={error}>
        {label}
      </Label>
      <ListWrapper theme={theme}>
        <TextField
          active
          border={false}
          leftIcon={leftIcon}
          mb={0}
          name={name}
          onChange={setSearchInput}
          placeholder={placeholder}
          value={searchInput}
        />
        <OptionsContainer ref={ref} height={optionsHeight} theme={theme}>
          {finalOptions.map((option) => {
            const isSelected = values.includes(option?.value);
            const displayValue = option?.label || option.value;

            return (
              <Option key={option.value} theme={theme}>
                <Checkbox
                  checked={isSelected}
                  disabled={option.disabled}
                  onChange={(v) => onClickValue(option.value, !isSelected)}
                  label={displayValue}
                />
              </Option>
            );
          })}
        </OptionsContainer>
      </ListWrapper>
    </Fragment>
  );
};

SearchableList.propTypes = {
  error: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  help: PropTypes.node,
  isNullAllowed: PropTypes.bool,
  label: PropTypes.string,
  leftIcon: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.bool),
    PropTypes.arrayOf(PropTypes.number),
    PropTypes.arrayOf(
      PropTypes.shape({
        disabled: PropTypes.bool,
        label: PropTypes.oneOfType([
          PropTypes.element,
          PropTypes.node,
          PropTypes.string,
        ]),
        value: PropTypes.oneOfType([
          PropTypes.bool,
          PropTypes.number,
          PropTypes.string,
        ]),
      }),
    ),
  ]),
  optionsHeight: PropTypes.number,
  placeholder: PropTypes.string,
  value: PropTypes.array,
};

SearchableList.defaultProps = {
  isNullAllowed: false,
  leftIcon: 'glass',
  onChange: noop,
  options: [],
  optionsHeight: 200,
  placeholder: 'Search items',
  value: [],
};

export default SearchableList;
