import {propOr} from 'ramda';

import {noop} from '@renofi/utilities';

import useToggledQuery from '../useToggledQuery';
import {GET_LOAN_APPLICATIONS_BY_PROJECT} from '../../queries';

export default function useLoanApplicationsByProject({
  fetchPolicy = 'cache-first',
  lazy = false,
  onCompleted = noop,
  ...options
} = {}) {
  const response = useToggledQuery({
    lazy,
    query: GET_LOAN_APPLICATIONS_BY_PROJECT,
    options: {
      onCompleted,
      fetchPolicy,
      ...options,
    },
  });

  const {data, error, loading, fetch, refetch} = response;
  const lenderLoanApplications = propOr(
    [],
    'lenderLoanApplicationsByProjectId',
    data,
  );

  return {fetch, refetch, lenderLoanApplications, data, loading, error};
}
