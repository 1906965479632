import {groupBy} from 'lodash';

import capitalize from '@renofi/utilities/src/capitalize';

import {ALL_FACETS} from './renovation/constants';

/**
 * @param {Object} project
 * @param {Object[]}} project.tasks
 */
export default ({tasks = []} = {}) => {
  const grouped = groupBy(tasks, 'facet');
  return ALL_FACETS.reduce((arr, facet) => {
    const group = grouped?.[facet] || [];
    return arr.concat({
      label: capitalize(facet),
      options: group.map(({id, title}) => ({label: title, value: id})),
    });
  }, []);
};
