import {propOr} from 'ramda';

import {noop} from '@renofi/utilities';

import useToggledQuery from '../../useToggledQuery';
import {GET_FEASIBILITY_REVIEWS_SEARCH} from '../../../queries';

export default function useFesibilityReviewsSearch({
  onCompleted = noop,
  fetchPolicy = 'cache-first',
  lazy = false,
  ...options
} = {}) {
  const response = useToggledQuery({
    lazy,
    query: GET_FEASIBILITY_REVIEWS_SEARCH,
    options: {
      onCompleted,
      fetchPolicy,
      ...options,
    },
  });

  const {data, error, loading, fetch} = response;
  const feasibilityReviewsSearch = propOr(
    null,
    'feasibilityReviewsSearch',
    data,
  );

  return {error, fetch, feasibilityReviewsSearch, loading};
}
