import React from 'react';

import PropTypes from 'prop-types';
import {datadogRum} from '@datadog/browser-rum';

import {RENOFI_GRAPHQL_ERROR} from '@renofi/graphql';

import FallbackComponent from './FallbackComponent';

export default class ErrorBoundary extends React.Component {
  static propTypes = {
    FallbackComponent: PropTypes.func,
    formatter: PropTypes.func,
    props: PropTypes.object,
  };

  static defaultProps = {
    FallbackComponent,
    formatter: () => {},
  };

  static getDerivedStateFromError(error) {
    return {error};
  }

  constructor(props) {
    super(props);
    this.state = {error: null};
  }

  componentDidCatch(error) {
    const isGraphQlError = error.message.includes(RENOFI_GRAPHQL_ERROR);

    if (!isGraphQlError) {
      datadogRum.addError(error);
    }
  }

  render() {
    const {FallbackComponent, formatter, props} = this.props;
    const {error} = this.state;
    if (error) {
      return (
        <FallbackComponent
          {...{error, details: formatter ? formatter(props) : null}}
        />
      );
    }
    // eslint-disable-next-line react/prop-types
    return this.props.children;
  }
}
