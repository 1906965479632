import {useCallback, useEffect, useState} from 'react';

import {useParams} from 'react-router-dom';
import {assoc, propOr, without} from 'ramda';
import {useProjectShallowTaskDocuments} from 'api';

import {
  GET_APPRAISAL_ORDERS,
  useSubmitAppraisalOrderFinalInspection,
  useSubmitAppraisalOrder,
} from '@renofi/api';
import {useNotifications} from '@renofi/utilities/src/hooks';

import {
  getAppraisalOrderVariables,
  getIsOrderParamsComplete,
  getAllDocs,
  getAllIds,
} from './index';

export default function useGetAppraisalOrder({
  onClose,
  previousAppraisalOrderId,
}) {
  const {projectId} = useParams();
  const [orderParams, setOrderParams] = useState(null);
  const [appraisalProduct, setAppraisalProduct] = useState(null);
  const [amcCode, setAmcCode] = useState(null);
  const [selected, setSelected] = useState([]);
  const {addNotification} = useNotifications();
  const refetchQueries = [
    {
      query: GET_APPRAISAL_ORDERS,
      variables: {projectId},
    },
  ];

  const {loading: isLoadingTasks, project} = useProjectShallowTaskDocuments({
    variables: {id: projectId},
  });
  const propertyInfo = project?.borrowersAndPropertyInformation || {};
  const tasks = propOr([], 'tasks', project);
  const documents = getAllDocs(tasks);

  const {loading, submitAppraisalOrder} = useSubmitAppraisalOrder({
    refetchQueries,
  });
  const {loading: loadingFinal, submitAppraisalOrderFinalInspection} =
    useSubmitAppraisalOrderFinalInspection({refetchQueries});

  const isLoading = loading || loadingFinal || isLoadingTasks;
  const isFinalInspection = appraisalProduct?.finalInspection;

  const invalidFields = getIsOrderParamsComplete({
    amcCode,
    appraisalProduct,
    orderParams,
  });

  useEffect(() => {
    if (!project) {
      return;
    }

    const borrower = propOr(null, 'borrower', project);
    const propertyDetails = {
      city: propertyInfo?.propertyCity,
      county: propertyInfo?.propertyCounty,
      propertyType: propertyInfo?.propertyType,
      state: propertyInfo?.propertyState,
      streetAddressOne: propertyInfo?.propertyStreetAddressOne,
      streetAddressTwo: propertyInfo?.propertyStreetAddressTwo,
      zipCode: propertyInfo?.propertyZipCode,
    };

    setOrderParams({
      contactFirstName: propertyInfo?.firstName,
      contactLastName: propertyInfo?.lastName,
      contactEmail: borrower?.email,
      contactHomePhone: borrower?.phoneNumber,
      contactWorkPhone: borrower?.phoneNumber,
      priorityOrder: false,
      propertyDetails,
    });
  }, [project]);

  useEffect(() => {
    setSelected(isFinalInspection ? [] : getAllIds(documents));
  }, [isFinalInspection, tasks.length]);

  const onCancel = useCallback(() => {
    setOrderParams(null);
  }, []);

  const onSubmit = async () => {
    const variables = getAppraisalOrderVariables({
      amcCode,
      appraisalProduct,
      isFinalInspection,
      projectId,
      orderParams,
      previousAppraisalOrderId,
      selected,
    });

    try {
      const mutation = isFinalInspection
        ? submitAppraisalOrderFinalInspection
        : submitAppraisalOrder;
      await mutation({variables});
    } catch (error) {
      const message = error?.errors?.graphQLErrors[0]?.message;

      addNotification({
        variant: 'danger',
        content: `There was a problem submitting the appraisal order. Details: ${message}`,
      });
    }

    onClose();
  };

  const onToggleDocument = useCallback(
    (id) => {
      setSelected(
        selected.includes(id) ? without([id], selected) : selected.concat(id),
      );
    },
    [selected],
  );

  const onUpdate = useCallback(
    (field, value) => {
      setOrderParams(assoc(field, value, orderParams));
    },
    [orderParams],
  );

  return {
    amcCode,
    appraisalProduct,
    documents,
    isFinalInspection,
    isLoading,
    onCancel,
    onSubmit,
    onToggleDocument,
    onUpdate,
    setAppraisalProduct,
    orderParams,
    selected,
    setAmcCode,
    invalidFields,
  };
}
