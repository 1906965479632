import styled from '@emotion/styled';

import {Box} from '@renofi/components-internal';
import {basic80, minSmall} from '@renofi/theme';

export const DocumentCard = styled(Box)(({internal}) => {
  const padding = internal ? 0 : 8;
  return {
    width: '100%',

    [minSmall]: {
      width: !internal ? '50%' : '100%',
      paddingLeft: padding,
      ':nth-of-type(2n + 1)': {
        paddingLeft: 0,
        paddingRight: padding,
      },
    },
  };
});

export const TotalAssets = styled.strong({
  color: basic80,
});
