import {pathOr} from 'ramda';

import {noop} from '@renofi/utilities';

import useToggledQuery from '../../useToggledQuery';
import {UNDERWRITING_CONDITIONS_SEARCH} from '../../../queries';

export default function useUnderwritingConditionsSearch({
  onCompleted = noop,
  fetchPolicy = 'cache-first',
  lazy = false,
  ...options
} = {}) {
  const {data, error, loading, fetch, refetch} = useToggledQuery({
    query: UNDERWRITING_CONDITIONS_SEARCH,
    lazy,
    options: {
      onCompleted,
      fetchPolicy,
      ...options,
    },
  });

  const underwritingConditions = pathOr(
    [],
    ['underwritingConditionsSearch', 'collection'],
    data,
  );
  const metadata = pathOr(
    null,
    ['underwritingConditionsSearch', 'metadata'],
    data,
  );

  return {underwritingConditions, metadata, loading, error, fetch, refetch};
}
