import {pathOr} from 'ramda';

import {compose, withHandlers} from '@renofi/recompose';
import {withApollo, withMutation, GET_PROJECT} from '@renofi/graphql';

import {SET_APPRAISAL_PROPERTY_PHOTO_SHAREABLE} from '../mutations';

export default () =>
  compose(
    withApollo,
    withMutation(SET_APPRAISAL_PROPERTY_PHOTO_SHAREABLE, {
      name: 'setShareablePropertyPhoto',
    }),
    withHandlers({
      setShareablePropertyPhoto:
        ({client, setShareablePropertyPhoto}) =>
        async ({id, projectId, shareable}) => {
          const {projectById} = client.readQuery({
            query: GET_PROJECT,
            variables: {id: projectId},
          });
          const {tasks, ...project} = projectById;
          client.writeQuery({
            query: GET_PROJECT,
            data: {
              projectById: {
                ...project,
                tasks: tasks.map((task) => {
                  const {appraisalPropertyPhotos, taskType} = task;
                  if (taskType === 'appraisal_property_photos') {
                    return {
                      ...task,
                      appraisalPropertyPhotos: appraisalPropertyPhotos.map(
                        (photo) => {
                          if (photo.id === id) {
                            return {
                              ...photo,
                              shareableWithAmc: shareable,
                            };
                          }
                          return photo;
                        },
                      ),
                    };
                  }
                  return task;
                }),
              },
            },
          });
          const response = await setShareablePropertyPhoto({
            variables: {id, shareable},
          });
          return pathOr(null, [
            'data',
            'setAppraisalPropertyPhotoShareable',
            'appraisalPropertyPhoto',
          ])(response);
        },
    }),
  );
