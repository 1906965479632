import {noop} from '@renofi/utilities';

import useToggledQuery from '../useToggledQuery';
import {GET_LENDERS} from '../../queries';

export default function useLenders({
  fetchPolicy = 'cache-first',
  lazy = false,
  onCompleted = noop,
  ...options
} = {}) {
  const response = useToggledQuery({
    lazy,
    query: GET_LENDERS,
    options: {
      onCompleted,
      fetchPolicy,
      ...options,
    },
  });

  const {data, error, loading, fetch} = response;
  const lenders = data?.lenders || [];
  const collection = data?.lenders?.collection || [];
  const metadata = data?.lenders?.metadata || {};

  return {fetch, lenders, data, loading, error, collection, metadata};
}
