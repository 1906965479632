import PropTypes from 'prop-types';

import {Box} from '@renofi/components-internal';
import {noop} from '@renofi/utilities';

import BorrowerEmploymentHistory from './BorrowerEmploymentHistory';
import {getHistory} from './utils';

const EmploymentHistoryDetails = ({
  areActionsDisabled = true,
  borrowerName,
  coborrowerName,
  internal = false,
  onAccept = noop,
  onClickDocument = noop,
  onClick = noop,
  onReject = noop,
  showDocuments = true,
  task,
}) => {
  const {employmentHistories = []} = task;
  const borrowerHistory = employmentHistories.filter(getHistory(false));
  const commonProps = {
    areActionsDisabled,
    internal,
    onAccept,
    onClick,
    onClickDocument,
    onReject,
    showDocuments,
  };

  return (
    <Box flex={1}>
      <BorrowerEmploymentHistory
        borrowerName={borrowerName}
        borrowerRole="borrower"
        employmentHistory={borrowerHistory}
        {...commonProps}
      />

      {Boolean(coborrowerName) ? (
        <BorrowerEmploymentHistory
          borrowerName={coborrowerName}
          borrowerRole="coborrower"
          employmentHistory={employmentHistories.filter(getHistory(true))}
          {...commonProps}
        />
      ) : null}
    </Box>
  );
};

EmploymentHistoryDetails.propTypes = {
  areActionsDisabled: PropTypes.bool,
  borrowerName: PropTypes.string.isRequired,
  coborrowerName: PropTypes.string,
  internal: PropTypes.bool,
  onAccept: PropTypes.func,
  onClickDocument: PropTypes.func,
  onClick: PropTypes.func,
  onReject: PropTypes.func,
  showDocuments: PropTypes.bool,
  task: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
};

export default EmploymentHistoryDetails;
