import {pathOr} from 'ramda';

import {noop} from '@renofi/utilities';

import useToggledQuery from '../useToggledQuery';
import {GET_TASK} from '../../queries';

export default function useTaskById({
  fetchPolicy = 'cache-first',
  lazy = false,
  onCompleted = noop,
  ...options
} = {}) {
  const response = useToggledQuery({
    lazy,
    query: GET_TASK,
    options: {
      fetchPolicy,
      onCompleted,
      ...options,
    },
  });
  const {data, error, loading, fetch, refetching} = response;
  const task = pathOr(null, ['taskById'], data);

  return {fetch, task, data, loading, error, refetching};
}
