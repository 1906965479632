import {propOr} from 'ramda';

import {noop} from '@renofi/utilities';

import useMutation from '../../useMutation';
import {GET_PROJECT_TASKS} from '../../../queries';
import {MOVE_TASK_DOCUMENTS} from '../../../mutations';

export default function useMoveTaskDocuments({onCompleted = noop} = {}) {
  const [moveTaskDocuments, {data, loading, error}] = useMutation(
    MOVE_TASK_DOCUMENTS,
    {
      onCompleted,
      refetchQueries: [GET_PROJECT_TASKS],
    },
  );

  const response = propOr(null, 'moveTaskDocuments', data);

  return {moveTaskDocuments, response, loading, error};
}
