import {noop} from 'lodash';
import {useMutation} from '@apollo/client';

import {SUBMIT_APPRAISAL_ORDER} from '../../../mutations';

export default function useSubmitAppraisalOrder({
  onCompleted = noop,
  refetchQueries = [],
} = {}) {
  const [submitAppraisalOrder, {data, loading, error}] = useMutation(
    SUBMIT_APPRAISAL_ORDER,
    {
      onCompleted,
      refetchQueries,
    },
  );
  const response = data?.submitAppraisalOrder?.appraisalOrder;

  return {submitAppraisalOrder, response, loading, error};
}
