import {pathOr} from 'ramda';

import {noop} from '@renofi/utilities';

import useMutation from '../../useMutation';
import {UPDATE_QUESTIONNAIRE} from '../../../mutations';

export default function useUpdateQuestionnaire({onCompleted = noop} = {}) {
  const [updateQuestionnaire, {data, loading, error}] = useMutation(
    UPDATE_QUESTIONNAIRE,
    {
      onCompleted,
    },
  );
  const questionnaire = pathOr(
    null,
    ['updateQuestionnaire', 'questionnaire'],
    data,
  );

  return {updateQuestionnaire, questionnaire, loading, error};
}
