import {propOr} from 'ramda';

import {noop} from '@renofi/utilities';

import {RRF_TRANSMITTED_BORROWER_NOTIFICATION} from '../../queries';
import useToggledQuery from '../useToggledQuery';

export default function useRRFTransmittedBorrowerNotification({
  fetchPolicy = 'cache-first',
  lazy = false,
  onCompleted = noop,
  ...options
} = {}) {
  const {data, error, loading, fetch} = useToggledQuery({
    lazy,
    query: RRF_TRANSMITTED_BORROWER_NOTIFICATION,
    options: {
      fetchPolicy,
      onCompleted,
      ...options,
    },
  });

  const response = propOr(
    null,
    'renovationReadyFileTransmittedBorrowerNotification',
    data,
  );
  return {error, fetch, response, loading};
}
