import {pathOr} from 'ramda';

import {noop} from '@renofi/utilities';

import {GET_PROJECTS_QUICK_SEARCH} from '../../queries';
import useToggledQuery from '../useToggledQuery';

export default function useProjectsQuickSearch({
  fetchPolicy = 'cache-first',
  lazy = false,
  onCompleted = noop,
  ...options
} = {}) {
  const {data, fetch, loading, error} = useToggledQuery({
    lazy,
    query: GET_PROJECTS_QUICK_SEARCH,
    options: {
      fetchPolicy,
      onCompleted,
      ...options,
    },
  });

  const projects = pathOr([], ['projectsSearch', 'collection'], data);
  const metadata = pathOr(null, ['projectsSearch', 'metadata'], data);

  return {fetch, projects, metadata, data, loading, error};
}
