import {noop} from '@renofi/utilities';

import useMutation from '../../useMutation';
import {SUBMIT_REVIEW_NOTES} from '../../../mutations';

export default function useSubmitReviewNotes({
  onCompleted = noop,
  refetchQueries = [],
} = {}) {
  const [submitReviewNotes, {data, loading, error}] = useMutation(
    SUBMIT_REVIEW_NOTES,
    {
      onCompleted,
      refetchQueries,
    },
  );
  const response = data?.submitReviewNotes?.gcddReview;

  return {submitReviewNotes, response, loading, error};
}
