import {pathOr} from 'ramda';

import {noop} from '@renofi/utilities';

import {CREATE_NOTE} from '../../mutations';
import {GET_PROJECT_NOTES} from '../../queries';
import useMutation from '../useMutation';

export default function useCreateNote({
  onCompleted = noop,
  refetchQueries = [GET_PROJECT_NOTES],
} = {}) {
  const [createNote, {data, loading, error}] = useMutation(CREATE_NOTE, {
    onCompleted,
    refetchQueries,
  });
  const response = pathOr(null, ['createNote'], data);
  return {createNote, response, loading, error};
}
