import useToggledQuery from '../../useToggledQuery';
import {GET_APPRAISAL_COMPANIES} from '../../queries';

export default function useAppraisalManagementCompanies({
  fetchPolicy = 'cache-first',
  lazy = false,
  ...options
} = {}) {
  const response = useToggledQuery(GET_APPRAISAL_COMPANIES, {
    lazy,
    fetchPolicy,
    ...options,
  });

  const {data, error, loading, fetch} = response;
  const {appraisalManagementCompanies = []} = data || {};

  return {error, fetch, appraisalManagementCompanies, loading};
}
