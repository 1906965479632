import {pathOr} from 'ramda';

import {noop} from '@renofi/utilities';

import useMutation from '../hooks/useMutation';

export default ({query, responsePath = [], responseFallback = null, name}) => {
  return ({onCompleted = noop, refetchQueries = [], ...options} = {}) => {
    const [mutation, {data, loading, error, abort, client}] = useMutation(
      query,
      {
        onCompleted,
        refetchQueries,
        ...options,
      },
    );

    const response = pathOr(responseFallback, responsePath, data);

    return {
      [name]: mutation,
      response,
      loading,
      error,
      abort,
      client,
    };
  };
};
