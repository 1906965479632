/* eslint-disable max-lines */
import {gql} from '@apollo/client';

export default gql`
  query projectById($id: ID!) {
    projectById(id: $id) {
      id
      renovationUpdateRemindersDisabledAt
    }
  }
`;
