import {pathOr} from 'ramda';

import {noop} from '@renofi/utilities';

import useMutation from '../useMutation';
import {SET_DOCUMENT_SHARABLE} from '../../mutations';

export default function useSetDocumentSharable({
  onCompleted = noop,
  refetchQueries = [],
} = {}) {
  const [setDocumentSharable, {data, loading, error}] = useMutation(
    SET_DOCUMENT_SHARABLE,
    {
      onCompleted,
      refetchQueries,
    },
  );
  const response = pathOr(null, ['setDocumentSharable'], data);
  return {setDocumentSharable, response, loading, error};
}
