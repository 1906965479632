import {useMutation} from '@apollo/client';
import {noop} from 'lodash';

import {GENERATE_COMBINED_PDF} from '../../mutations';

export default function useGenerateCombinedPdf({
  onCompleted = noop,
  refetchQueries = [],
  ...options
} = {}) {
  const [generateCombinedPdf, {data, loading, error}] = useMutation(
    GENERATE_COMBINED_PDF,
    {
      onCompleted,
      refetchQueries,
      ...options,
    },
  );

  const response = data?.generateCombinedPdf;
  return {generateCombinedPdf, response, loading, error};
}
