import {pathOr} from 'ramda';

import {noop} from '@renofi/utilities';

import useMutation from '../../useMutation';
import {REQUEST_QUESTIONNAIRE_RESEND} from '../../../mutations';

export default function useRequestQuestionnaireResend({
  onCompleted = noop,
} = {}) {
  const [requestQuestionnaireResend, {data, loading, error}] = useMutation(
    REQUEST_QUESTIONNAIRE_RESEND,
    {
      onCompleted,
    },
  );

  const success = pathOr(null, ['requestQuestionnaireResend', 'success'], data);

  return {requestQuestionnaireResend, success, loading, error};
}
