import React, {createRef, PureComponent} from 'react';

import {createPortal} from 'react-dom';
import PropTypes from 'prop-types';

import {noop} from '@renofi/utilities';

import List from './List';
import {Dots, Label, Wrapper} from './styled';

class Dropdown extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      rect: {},
    };
    this.ref = createRef();
  }

  onToggle = (event) => {
    const {disabled, onClick} = this.props;
    const {open} = this.state;

    event.stopPropagation();

    if (!disabled) {
      this.setState({open: !open});
      onClick(!open);
    }
  };

  onClose = () => {
    this.setState({open: false});
  };

  componentDidMount() {
    if (this.ref && this.ref.current) {
      this.setState({rect: this.ref.current.getBoundingClientRect()});
    }
  }

  render() {
    const {
      children,
      disabled,
      heading,
      label,
      local = true,
      fixed = false,
      pure = false,
      overflow,
      position = 'left',
      width,
      ...props
    } = this.props;
    const {rect} = this.state;
    const content = this.state.open ? (
      <List
        rect={rect}
        fixed={fixed}
        local={local}
        label={label}
        heading={heading}
        position={position}
        overflow={overflow}
        onClose={this.onClose}>
        {children}
      </List>
    ) : null;

    return (
      <div ref={this.ref}>
        <Wrapper width={width} onClick={this.onToggle} {...props}>
          {label ? (
            <Label onClick={this.onToggle} pure={pure}>
              {label}
            </Label>
          ) : (
            <Dots active={this.state.open} onClick={this.onToggle} />
          )}
          {local ? content : createPortal(content, document.body)}
        </Wrapper>
      </div>
    );
  }
}

Dropdown.propTypes = {
  pure: PropTypes.bool,
  disabled: PropTypes.bool,
  fixed: PropTypes.bool,
  label: PropTypes.node,
  local: PropTypes.bool,
  width: PropTypes.number,
  heading: PropTypes.node,
  onClick: PropTypes.func,
  position: PropTypes.string,
  overflow: PropTypes.string,
  children: PropTypes.node.isRequired,
  css: PropTypes.object,
};

Dropdown.defaultProps = {
  onClick: noop,
};

Dropdown.displayName = 'Dropdown';

export default Dropdown;
