import React from 'react';

import styled from '@emotion/styled';
import PropTypes from 'prop-types';

import {basic15} from '@renofi/theme/src/colors';
import {noop} from '@renofi/utilities';

const ANGLES = {
  up: 0,
  down: 180,
  left: -90,
  right: 90,
};

const Svg = styled('svg')(({direction}) => {
  const angle = ANGLES[direction] || 0;

  return {
    transform: `rotate(${angle}deg)`,
    transformOrigin: 'center',
  };
});

const DEFAULT_WIDTH = 16;

function SortArrowAlt({
  direction = 'up',
  color = basic15,
  onClick = noop,
  width = DEFAULT_WIDTH,
}) {
  return (
    <Svg
      direction={direction}
      width={width}
      height={width}
      viewBox="0 0 20 20"
      fill="none"
      onClick={onClick}
      xmlns="http://www.w3.org/2000/svg">
      <g id="Select-menu-expand">
        <g id="arrow_downward_alt">
          <mask
            id="mask0_4853_6654"
            style={{maskType: 'alpha'}}
            maskUnits="userSpaceOnUse"
            x="0"
            y="-2"
            width={width}
            height={width}>
            <rect
              id="Bounding box"
              width={width}
              height={width}
              transform="matrix(1 0 0 -1 0.414062 18.75)"
              fill={basic15}
            />
          </mask>
          <g mask="url(#mask0_4853_6654)">
            <path
              id="arrow_downward_alt_2"
              d="M10.4141 5.75065L5.41406 10.7507L6.58073 11.9173L9.58073 8.91732V16.584H11.2474V8.91732L14.2474 11.9173L15.4141 10.7507L10.4141 5.75065Z"
              fill={color}
            />
          </g>
        </g>
      </g>
    </Svg>
  );
}

SortArrowAlt.propTypes = {
  direction: PropTypes.oneOf(['up', 'down', 'left', 'right']),
  color: PropTypes.string,
  onClick: PropTypes.func,
  width: PropTypes.number,
};

export default SortArrowAlt;
