import {noop} from 'lodash';
import {useMutation} from '@apollo/client';

import {ACCEPT_REPORT} from '../../../mutations';

export default function useAcceptReport({onCompleted = noop} = {}) {
  const [acceptReport, {data, loading, error}] = useMutation(ACCEPT_REPORT, {
    onCompleted,
  });
  const response = data?.acceptReport;

  return {acceptReport, response, loading, error};
}
