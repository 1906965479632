import React, {useCallback, useEffect} from 'react';

import PropTypes from 'prop-types';
import printJS from 'print-js';
import {propEq, propOr} from 'ramda';

import {useSignedUrl} from '@renofi/api';
import {Printer as PrinterIcon} from '@renofi/icons';
import {white} from '@renofi/theme';
import {getObjectName} from '@renofi/utilities';
import {useKeyHandler} from '@renofi/utilities/src/hooks';

import Box from '../../Box';
import Downloads from '../../Downloads';
import Flex from '../../Flex';
import Link from '../../Link';
import BasicModal from '../../BasicModal';
import Navbar from '../../Navbar';
import Text from '../../Text';
import Viewer from '../Viewer';
import {getPrintableType} from '../utils';

import {Back, ButtonBG, Next, Prev, StyledCross} from './styled';

const Carousel = ({
  documents,
  visible,
  onClose,
  onNext,
  onPrev,
  itemId,
  onSetCurrentPdfUrl = () => {},
  showCloseButton = false,
  showBackButton = true,
  showDownloadButton = false,
  isNestedModal = false,
  ...props
}) => {
  const current = itemId ? documents.findIndex(propEq('id', itemId)) : 0;
  const currentDoc = documents[current];
  const {fileName} = currentDoc || {};
  const objectName = getObjectName(currentDoc);
  const {fetch, signedUrl} = useSignedUrl({lazy: true});
  const pdfFileUrl = propOr(null, 'url', signedUrl);

  useEffect(() => {
    if (objectName) {
      fetch({variables: {objectName}});
    }
  }, [objectName]);

  useEffect(() => {
    if (pdfFileUrl) {
      onSetCurrentPdfUrl(pdfFileUrl);
    }
  }, [pdfFileUrl]);

  const printHandler = useCallback(
    (event) => {
      event.preventDefault();
      printJS({printable: pdfFileUrl, type: getPrintableType(fileName)});
    },
    [pdfFileUrl],
  );

  const onNextDocument = useCallback(
    (event) => {
      event.stopPropagation();
      if (documents.length === current + 1) {
        return;
      }
      const {id} = documents[current + 1];
      onNext(id);
    },
    [current, documents],
  );

  const onPrevDocument = useCallback(
    (event) => {
      event.stopPropagation();
      if (current === 0) {
        return;
      }

      const {id} = documents[current - 1];
      onPrev(id);
    },
    [current, documents],
  );

  useKeyHandler({
    ArrowLeft: onPrevDocument,
    ArrowRight: onNextDocument,
  });

  if (!currentDoc) {
    return null;
  }

  return (
    <>
      {visible && (
        <Navbar
          fluid
          fixed
          variant="alternative"
          css={{zIndex: 1999, position: 'fixed', top: 0, left: 0}}
          onClick={(event) => event.stopPropagation()}>
          <Flex alignItems="center" justifyContent="space-between">
            <Flex alignItems="center">
              {showBackButton && <Back onClick={(event) => onClose(event)} />}
              <Text fontSize={16} lineHeight="20px" pl={24}>
                {currentDoc.fileName}
              </Text>
            </Flex>

            {showCloseButton ? (
              <Flex alignItems="center">
                {showDownloadButton ? (
                  <Box mr={24}>
                    <Downloads {...currentDoc} color="white" />
                  </Box>
                ) : null}
                <Box mr={24}>
                  <StyledCross onClick={onClose} />
                </Box>
              </Flex>
            ) : (
              <Flex alignItems="center">
                <Box mr={24}>
                  <Downloads {...currentDoc} color="white" />
                </Box>
                <Link blank href={pdfFileUrl} onClick={printHandler}>
                  <PrinterIcon color={white} />
                </Link>
              </Flex>
            )}
          </Flex>
        </Navbar>
      )}

      <BasicModal
        isNestedModal={isNestedModal}
        css={{height: 'auto', top: 70, bottom: 0}}
        onClose={(event) => onClose(event)}>
        {current > 0 && visible && (
          <ButtonBG
            css={{left: '53%', marginLeft: -754}}
            onClick={onPrevDocument}>
            <Prev />
          </ButtonBG>
        )}

        <Viewer document={currentDoc} {...props} />

        {current < documents.length - 1 && visible && (
          <ButtonBG
            css={{right: '53%', marginRight: -754}}
            onClick={onNextDocument}>
            <Next />
          </ButtonBG>
        )}
      </BasicModal>
    </>
  );
};

Carousel.propTypes = {
  itemId: PropTypes.string,
  documents: PropTypes.arrayOf(PropTypes.object).isRequired,
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  onPrev: PropTypes.func.isRequired,
  onSetCurrentPdfUrl: PropTypes.func,
  projectId: PropTypes.string,
  showBackButton: PropTypes.bool,
  showCloseButton: PropTypes.bool,
  showDownloadButton: PropTypes.bool,
  isNestedModal: PropTypes.bool,
};

export default Carousel;
