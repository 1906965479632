import useToggledQuery from '../../useToggledQuery';
import {GET_LOAN_TYPE_VALUES} from '../../queries';

export default function useLoanTypeValues({
  fetchPolicy = 'cache-first',
  lazy = false,
  ...options
} = {}) {
  const response = useToggledQuery(GET_LOAN_TYPE_VALUES, {
    lazy,
    fetchPolicy,
    ...options,
  });

  const {data, error, loading, fetch} = response;
  const loanTypeValues = data?.loanTypeValues || [];

  return {error, fetch, loanTypeValues, loading};
}
