import React, {useCallback, useState} from 'react';

import PropTypes from 'prop-types';

import {noop} from '@renofi/utilities';
import withForwardRef from '@renofi/utilities/src/withForwardRef';

import FieldFrame from './FieldFrame';
import FieldInput from './FieldInput';

const SimpleField = ({
  autoComplete,
  canClear = false,
  clearValue = null,
  defaultValue,
  disabled,
  forwardedRef,
  name,
  placeholder,
  tabIndex,
  type,
  value: currentValue,
  onBlur = noop,
  onChange = noop,
  onClear = noop,
  onFocus = noop,
  onKeyDown = noop,
  onKeyUp = noop,
  maxLength,
  ...props
}) => {
  const [focus, setFocus] = useState(false);
  const [value, setValue] = useState(currentValue || defaultValue || '');

  const changeHandler = useCallback((val) => {
    setValue(val);
    onChange(val);
  });

  const clearHandler = useCallback(() => {
    if (canClear) {
      setValue(clearValue);
      onClear(clearValue);
    }
  }, [canClear, clearValue]);

  const blurHandler = useCallback((event) => {
    setFocus(false);
    onBlur(event);
  });

  const focusHandler = useCallback((event) => {
    setFocus(true);
    onFocus(event);
  });

  return (
    <FieldFrame
      canClear={canClear}
      disabled={disabled}
      name={name}
      focused={focus}
      onClear={clearHandler}
      inputValue={value}
      {...props}>
      <FieldInput
        autoComplete={autoComplete}
        id={name}
        disabled={disabled}
        name={name}
        tabIndex={tabIndex}
        type={type}
        value={value}
        ref={forwardedRef}
        placeholder={placeholder}
        onChange={changeHandler}
        onBlur={blurHandler}
        onFocus={focusHandler}
        onKeyDown={onKeyDown}
        onKeyUp={onKeyUp}
        maxLength={maxLength}
      />
    </FieldFrame>
  );
};

SimpleField.propTypes = {
  autoComplete: PropTypes.string,
  canClear: PropTypes.bool,
  clearValue: PropTypes.any,
  defaultValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  disabled: PropTypes.bool,
  forwardedRef: PropTypes.any,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onClear: PropTypes.func,
  onFocus: PropTypes.func,
  onKeyDown: PropTypes.func,
  onKeyUp: PropTypes.func,
  tabIndex: PropTypes.number,
  maxLength: PropTypes.number,
};

export default withForwardRef(SimpleField);
