import useToggledQuery from '../../useToggledQuery';
import {GET_SIGNED_URLS} from '../../queries';

export default function useSignedUrls({
  fetchPolicy = 'cache-first',
  lazy = false,
  ...options
} = {}) {
  const response = useToggledQuery(GET_SIGNED_URLS, {
    lazy,
    options: {
      fetchPolicy,
      ...options,
    },
  });

  const {data, error, loading, fetch, refetch} = response;
  const signedUrls = data?.signedUrls || [];

  return {error, fetch, refetch, signedUrls, loading};
}
