import React from 'react';

import PropTypes from 'prop-types';
import {defaultTo, isNil, last, pathOr, pipe, reject, split} from 'ramda';

import {useSignedUrls} from '@renofi/api';
import DownloadIcon from '@renofi/icons/src/Download';

import Box from '../Box';
import Dropdown from '../Dropdown';
import FileType from '../FileType';
import Link from '../Link';
import Flex from '../Flex';

const Downloads = ({
  color,
  icon,
  fileName,
  objectName,
  pdfFileName: pdfName,
  pdfObjectName,
}) => {
  const pdfFileName =
    pdfName || pipe(defaultTo(''), split('/'), last)(pdfObjectName);
  const objectNames = reject(isNil, [objectName, pdfObjectName]);
  const variables = {objectNames};

  const {fetch, loading, signedUrls} = useSignedUrls({lazy: true});
  const fileUrl = pathOr(null, [0, 'url'], signedUrls);
  const pdfFileUrl = pathOr(null, [1, 'url'], signedUrls);

  return (
    <Dropdown
      pure
      local
      position="left"
      label={icon || <DownloadIcon color={color} />}
      onClick={() => fetch({variables})}
      css={{display: 'inline-block'}}>
      <Link
        blank
        disabled={loading}
        onClick={(event) => {
          event?.stopPropagation();
        }}
        href={fileUrl}
        css={{
          padding: '0 16px !important',
          width: '100%',
          maxWidth: 400,
          minWidth: 312,
        }}>
        <Flex width={1} alignItems="center" css={{height: 48}}>
          <FileType css={{flexShrink: 0}} fileName={fileName} />
          <Box
            ml={16}
            css={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}>
            {fileName}
          </Box>
        </Flex>
      </Link>
      {pdfFileName && pdfFileName !== fileName && (
        <Link
          blank
          disabled={loading}
          href={pdfFileUrl}
          onClick={(event) => {
            event?.stopPropagation();
          }}
          css={{
            padding: '0 16px !important',
            width: '100%',
            maxWidth: 400,
            minWidth: 312,
          }}>
          <Flex width={1} alignItems="center" css={{height: 48}}>
            <FileType css={{flexShrink: 0}} fileName={pdfFileName} />
            <Box
              ml={16}
              css={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}>
              {pdfFileName}
            </Box>
          </Flex>
        </Link>
      )}
    </Dropdown>
  );
};

Downloads.propTypes = {
  icon: PropTypes.node,
  color: PropTypes.string,
  fileName: PropTypes.string,
  objectName: PropTypes.string,
  pdfFileName: PropTypes.string,
  pdfObjectName: PropTypes.string,
};

export default Downloads;
