import useToggledQuery from '../../useToggledQuery';
import {GET_IS_FILE_PASSWORD_VALID} from '../../queries';

export default function useIsFilePasswordValid({
  fetchPolicy = 'no-cache',
  lazy = false,
  ...options
} = {}) {
  const response = useToggledQuery(GET_IS_FILE_PASSWORD_VALID, {
    lazy,
    fetchPolicy,
    ...options,
  });

  const {data, error, loading, fetch} = response;
  const isValid = data?.isFilePasswordValid;

  return {error, fetch, isValid, loading};
}
