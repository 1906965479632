import {pathOr} from 'ramda';

import {noop} from '@renofi/utilities';

import useToggledQuery from '../../useToggledQuery';
import {NOTES_SEARCH} from '../../../queries';

export default function useNotesSearch({
  onCompleted = noop,
  fetchPolicy,
  query = NOTES_SEARCH,
  lazy = false,
  ...options
} = {}) {
  const {data, error, loading, fetch, refetch} = useToggledQuery({
    query,
    lazy,
    options: {
      onCompleted,
      fetchPolicy,
      ...options,
    },
  });

  const notes = pathOr([], ['notesSearch', 'collection'], data);
  const metadata = pathOr(null, ['notesSearch', 'metadata'], data);

  return {notes, metadata, loading, error, fetch, refetch};
}
