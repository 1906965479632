import {useContext} from 'react';

import {propOr} from 'ramda';

import {getUserId} from '@renofi/utilities/src/auth';
import {noop} from '@renofi/utilities';
import {Context as StorageContext} from '@renofi/utilities/src/storage';
import logger from '@renofi/utilities/src/logger';

import useToggledQuery from '../useToggledQuery';
import {GET_USER_BY_ID} from '../../queries';

export default function useGetUser({
  fetchPolicy = 'cache-first',
  lazy = false,
  onCompleted = noop,
  ...options
} = {}) {
  const storage = useContext(StorageContext);
  const userId = getUserId(storage);
  const response = useToggledQuery({
    lazy,
    query: GET_USER_BY_ID,
    options: {
      variables: {id: userId},
      skip: !userId,
      onCompleted,
      fetchPolicy,
      ...options,
    },
  });

  const {data, error, loading, fetch} = response;
  const user = propOr(null, 'userById', data);

  const getUser = async () => {
    if (!userId) {
      logger.debug('[auth] 💀 No user ID found in storage');
      return null;
    }

    return fetch({variables: {id: userId}});
  };

  return {getUser, user, data, loading, error};
}
