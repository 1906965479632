import {gql} from '@apollo/client';

import {partials} from '@renofi/api';

import {
  appraisalPropertyDetails,
  appraisalRecentTransactions,
  appraisalRenovationDetails,
  contractor,
  documentWithAllProps,
  smsConsentRenovationProgress,
  advisorFinalCallSchedule,
  certificateAcknowledgement,
  productDetailsConfirmation,
  softCreditCheck,
  personalInfoAndResidency,
  realEstateOwned,
  employmentHistory,
  incomeSource,
  payStatementInformation,
  assetDetails,
  borrowersAndPropertyInformation,
} from '../../partials';

export default gql`
  query taskById($id: ID!) {
    taskById(id: $id) {
      id
      additionalExplanation
      additionalRequestMessage
      allDocumentsAccepted
      anyDocumentRejected
      allDocumentsReviewed
      appraisalComparableSales {
        id
        createdAt
        updatedAt
        urls
      }
      appraisalPropertyDetails {
        ${appraisalPropertyDetails}
      }
      appraisalPropertyPhotos {
        ${partials.appraisalPropertyPhoto}
      }
      appraisalRecentTransactions {
        ${appraisalRecentTransactions}
      }
      appraisalRenovationDetails {
        ${appraisalRenovationDetails}
      }
      advisorFinalCallSchedule {
        ${advisorFinalCallSchedule}
      }
      assetDetails {
        ${assetDetails}
      }
      certificateAcknowledgement {
        ${certificateAcknowledgement}
      }
      productDetailsConfirmation {
        ${productDetailsConfirmation}
      }
      softCreditCheck {
        ${softCreditCheck}
      }
      personalInfoAndResidency {
        ${personalInfoAndResidency}
      }
      realEstateOwned {
        ${realEstateOwned}
      }
      canHaveDocument
      completedAt
      contractor {
        ${contractor}
      }
      contractorInformations {
        ${contractor}
      }
      dataSubmittedAt
      details
      documents {
        ${documentWithAllProps}
        rejectedById
        rejectedBy {
          id
          firstName
          lastName
        }
      }
      employmentHistories {
        ${employmentHistory}
      }
      facet
      incomeSources {
        ${incomeSource}
      }
      informationKind
      payStatementInformations {
        ${payStatementInformation}
      }
      requirements
      smsConsentRenovationProgress {
        ${smsConsentRenovationProgress}
      }
      status
      submittedAt
      taskType
      title
      project {
        id
        borrower {
          firstName
          lastName
        }
        borrowersAndPropertyInformation {
          ${borrowersAndPropertyInformation}
        }
      }
      governmentIssuedIds {
        expirationDate
        id
        idNumber
        idType
        issueDate
        issuingCountry
        issuingState
        taskId
        borrowerRole
      }
    }
  }
`;
