import {noop} from '@renofi/utilities';

import useMutation from '../../useMutation';
import {REJECT_GCDD_DOCUMENTS} from '../../../mutations';

export default function useRejectGcddDocuments({
  onCompleted = noop,
  refetchQueries = [],
  ...options
} = {}) {
  const [rejectGcddDocuments, {data, loading, error}] = useMutation(
    REJECT_GCDD_DOCUMENTS,
    {
      onCompleted,
      refetchQueries,
      ...options,
    },
  );
  const response = data?.rejectGcddDocuments?.questionnaire || null;

  return {rejectGcddDocuments, response, loading, error};
}
