import {propOr} from 'ramda';

import {noop} from '@renofi/utilities';

import useToggledQuery from '../../useToggledQuery';
import {GET_PROJECT_RENOVATION_UPDATES} from '../../../queries';

export default function useProjectRenovationUpdatesV2({
  fetchPolicy = 'cache-first',
  lazy = false,
  onCompleted = noop,
  ...options
} = {}) {
  const response = useToggledQuery({
    lazy,
    query: GET_PROJECT_RENOVATION_UPDATES,
    options: {
      fetchPolicy,
      onCompleted,
      ...options,
    },
  });
  const {data, error, loading, fetch} = response;

  const renovationUpdates = propOr([], 'projectRenovationUpdatesV2', data);

  return {
    fetch,
    renovationUpdates,
    loading,
    error,
  };
}
