import {propOr} from 'ramda';

import {noop} from '@renofi/utilities';

import useToggledQuery from '../useToggledQuery';
import {GET_LENDER_PROVIDED_REPORTS} from '../../queries';

export default function useLenderProvidedReports({
  fetchPolicy = 'cache-first',
  lazy = false,
  onCompleted = noop,
  ...options
} = {}) {
  const response = useToggledQuery({
    lazy,
    query: GET_LENDER_PROVIDED_REPORTS,
    options: {
      onCompleted,
      fetchPolicy,
      ...options,
    },
  });

  const {data, error, loading, fetch} = response;
  const lenderProvidedReports = propOr([], 'lenderProvidedReports', data);
  return {fetch, lenderProvidedReports, data, loading, error};
}
