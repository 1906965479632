import {propOr} from 'ramda';

import {noop} from '@renofi/utilities';

import useMutation from '../../useMutation';
import {SEND_QUESTIONNAIRE_PDF} from '../../../mutations';

export default function useSendQuestionnairePdf({
  onCompleted = noop,
  refetchQueries = [],
} = {}) {
  const [sendQuestionnairePdf, {data, loading, error}] = useMutation(
    SEND_QUESTIONNAIRE_PDF,
    {
      onCompleted,
      refetchQueries,
    },
  );
  const questionnaire = propOr(null, 'sendQuestionnairePdf', data);
  return {sendQuestionnairePdf, questionnaire, loading, error};
}
