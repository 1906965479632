import React, {useMemo} from 'react';

import {MentionsInput, Mention} from 'react-mentions';
import PropTypes from 'prop-types';

import {getFullName} from '@renofi/utilities/src/data';
import {actionExtraLight, useTheme, white} from '@renofi/theme';
import {noop} from '@renofi/utilities';

import Box from '../Box';

import './styles.css';

const TextareaWithMentions = ({
  disabled = false,
  onChange = noop,
  resize = 'none',
  value,
  users = [],
  ...props
}) => {
  const {styles} = useTheme('textarea', {disabled, ...props});

  const data = useMemo(
    () =>
      users.map((u) => ({
        id: u.id,
        display: getFullName(u),
      })),
    [users?.length],
  );

  return (
    <Box>
      <MentionsInput
        style={{
          control: styles,
          '&multiLine': {
            control: {
              minHeight: 75,
            },
            highlighter: {
              padding: 8,
              backgroundColor: 'none',
              opacity: disabled ? 0 : 1,
              ...styles,
            },
            input: {
              padding: 8,
              overflow: resize !== 'none' ? 'auto' : 'hidden',
              resize: !disabled ? resize : 'none',
              zIndex: 1,
              ...styles,
            },
          },
          suggestions: {
            list: {
              backgroundColor: white,
              border: '1px solid rgba(0,0,0,0.15)',
              fontSize: 14,
            },
            item: {
              padding: '5px 15px',
              borderBottom: '1px solid rgba(0,0,0,0.15)',
              '&focused': {
                backgroundColor: actionExtraLight,
              },
            },
          },
        }}
        suggestionsPortalHost={document.body}
        className="react_mentions_wrapper"
        disabled={disabled}
        onChange={(e, newValue) => onChange(newValue)}
        value={value}>
        <Mention
          data={data}
          displayTransform={(id, display) => `@${display}`}
          trigger="@"
          markup="{{__id__|__display__}}"
        />
      </MentionsInput>
    </Box>
  );
};

TextareaWithMentions.propTypes = {
  disabled: PropTypes.bool,
  value: PropTypes.string,
  resize: PropTypes.oneOf(['both', 'horizontal', 'none', 'vertical']),
  users: PropTypes.array,
  onChange: PropTypes.func,
};

export default TextareaWithMentions;
