import {pathOr} from 'ramda';

import {noop} from '@renofi/utilities';

import useToggledQuery from '../../useToggledQuery';
import {GET_TASKS_OVERVIEW} from '../../../queries';

export default function useTasksOverview({
  onCompleted = noop,
  fetchPolicy = 'cache-first',
  lazy = true,
  ...options
} = {}) {
  const {data, error, loading, fetch} = useToggledQuery({
    query: GET_TASKS_OVERVIEW,
    lazy,
    options: {
      onCompleted,
      fetchPolicy,
      ...options,
    },
  });

  const tasksOverview = pathOr({}, ['projectById', 'tasksOverview'], data);
  return {tasksOverview, loading, error, fetch};
}
