import {pathOr} from 'ramda';

import {SUBMIT_REVISION_REQUEST} from '@renofi/api';
import {compose, withHandlers} from '@renofi/recompose';

import withApollo from './withApollo';
import withMutation from './withMutation';

export default () =>
  compose(
    withApollo,
    withMutation(SUBMIT_REVISION_REQUEST, {
      name: 'submitRevisionRequest',
      options: {
        refetchQueries: ['appraisalOrdersByProjectId'],
      },
    }),
    withHandlers({
      submitRevisionRequest:
        ({submitRevisionRequest}) =>
        async (variables) => {
          const response = await submitRevisionRequest({
            variables,
          });

          return pathOr(
            false,
            ['data', 'submitRevisionRequest', 'success'],
            response,
          );
        },
    }),
  );
