import {
  initDataDog,
  initSegment,
  initSentry,
  isTestSession,
} from '@renofi/analytics';
import isImpersonation from '@renofi/utilities/src/isImpersonation';
import googlePlaces from '@renofi/utilities/src/googlePlaces';

export default async ({config, storage}) => {
  const {DEVELOPMENT, GOOGLE_MAPS_API_KEY, PRODUCTION} = config;

  if (GOOGLE_MAPS_API_KEY) {
    googlePlaces(GOOGLE_MAPS_API_KEY);
  }

  const impersonation = isImpersonation(storage);

  initDataDog({ignoreErrors: ['csp_violation']});

  if (impersonation || isTestSession()) return;

  if (!DEVELOPMENT) {
    initSegment({integrations: {All: false, Heap: true}});
  }

  if (PRODUCTION) {
    initSentry();
  }
};
