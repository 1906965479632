import React, {Fragment, useRef} from 'react';

import PropTypes from 'prop-types';
import {useViewerNavigation} from 'modules/viewer-v2';
import {TaskItemActions} from 'modules/viewer-v2/components/Layout';

import queryString from '@renofi/utilities/src/queryString';
import {Box, Flex} from '@renofi/components-internal';
import {basic80, basic88} from '@renofi/theme';
import {Company, Description, House, PersonCircle} from '@renofi/icons';
import humanizeSnakeCase from '@renofi/utilities/src/humanizeSnakeCase';
import {noop} from '@renofi/utilities';

import {getBadgeIconColor} from '../utils';
import Tab from '../../Layout/components/ItemTabsBar/components/Tab';
import {ItemTabsContainer} from '../../Layout/components/ItemTabsBar';

import {Badge} from './styled';
import TabContent from './TabContent';

const getIcon = (tabType) => {
  switch (tabType) {
    case 'borrowerRole':
      return PersonCircle;
    case 'propertyId':
      return House;
    case 'employmentId':
      return Company;
    case 'unassigned':
      return Description;
    default:
      return null;
  }
};

const TaskItemsBar = ({
  currentTab,
  flattened = [],
  onAcceptComplete = noop,
  tabs = [],
  task,
}) => {
  const containerRef = useRef();
  const {history, search} = useViewerNavigation();
  const {id: taskId, taskType} = task || {};
  const {children = []} = currentTab || {};
  const currentId = search[children[0]?.tabType];
  const index = children.findIndex(({tabValue}) => tabValue === currentId);

  const onClickItemsArrow = (forward) => {
    let newIndex;

    if (forward) {
      newIndex = index === children.length - 1 ? 0 : index + 1;
    } else {
      newIndex = index === 0 ? children.length - 1 : index - 1;
    }

    const newItem = flattened.find(({id}) => children[newIndex]?.id === id);
    if (newItem?.query) {
      const qString = queryString.stringify(newItem.query);
      history.push(`?${qString}`);
    }
  };

  const onClickTab = ({tabType, tabValue} = {}) => {
    const found = flattened.find(({query}) => query[tabType] === tabValue);
    if (!found?.query) {
      return;
    }

    const qString = queryString.stringify(found.query);
    history.push(`?${qString}`);
  };

  return (
    <Fragment>
      <Flex alignItems="center" px={2} py={3} pl={20} backgroundColor={basic80}>
        {tabs.map((tab) => {
          const active = search[tab.tabType] === tab.tabValue;
          const Icon = getIcon(tab.tabType);
          const color = getBadgeIconColor(task?.status);

          return (
            <Badge
              pill
              active={active}
              key={tab.tabValue}
              onClick={() => onClickTab(tab)}>
              <Flex alignItems="center">
                {Boolean(Icon) && <Icon color={color} />}

                <Box ml={Boolean(Icon) ? 2 : 0} as="span">
                  {tab.label}
                </Box>
              </Flex>
            </Badge>
          );
        })}
      </Flex>

      {Boolean(children?.length) ? (
        <ItemTabsContainer
          isNextDisabled={index === children?.length - 1}
          isPrevDisabled={index === 0}
          onClickArrow={onClickItemsArrow}
          ref={containerRef}>
          <Flex width={1} height="100%" alignItems="flex-end">
            {(children || []).map((child) => {
              const active = search[child.tabType] === child.tabValue;
              const isDocument = child.__typename === 'Document';

              return (
                <Tab
                  activeColor={basic88}
                  active={active}
                  containerRef={containerRef}
                  id={child.id}
                  onClick={() => onClickTab(child)}
                  key={child.tabValue}>
                  <TabContent
                    extra={
                      isDocument ? (
                        <TaskItemActions
                          {...child}
                          onAcceptComplete={onAcceptComplete}
                          taskId={taskId}
                          taskType={taskType}
                        />
                      ) : null
                    }
                    subTitle={humanizeSnakeCase(child.documentType)}
                    title={child.label}
                  />
                </Tab>
              );
            })}
          </Flex>
        </ItemTabsContainer>
      ) : null}
    </Fragment>
  );
};

TaskItemsBar.propTypes = {
  currentTab: PropTypes.object,
  flattened: PropTypes.array,
  tabs: PropTypes.array,
  task: PropTypes.shape({
    canHaveDocument: PropTypes.bool,
    facet: PropTypes.string,
    id: PropTypes.string.isRequired,
    taskType: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }),
};

export default TaskItemsBar;
