import React from 'react';

import {createRoot} from 'react-dom/client';
import {propOr} from 'ramda';

import {apolloClient} from '@renofi/graphql';
import logger from '@renofi/utilities/src/logger';
import {noop} from '@renofi/utilities';

import AppRoot from './AppRoot';
import * as sharedConfig from './config';
import {configureAuthAndStorage} from './utils';

export default async ({
  apolloClientCache,
  onInit = noop,
  rootComponent,
  rootId = 'root',
  ...params
}) => {
  const appConfig = propOr({}, 'appConfig', params);
  const config = {...sharedConfig, ...appConfig};
  logger.debug('ENVIRONMENT', config.ENVIRONMENT);

  const serviceAccountId = config.SERVICE_ACCOUNT_ID;
  const graphQlUrl = config.GRAPHQL_PROXY_URL;

  if (!serviceAccountId || !graphQlUrl) {
    logger.error('[RENOFI 🐞] Service Account ID / GraphQL end-point missing', {
      serviceAccountId,
      graphQlUrl,
    });
  }

  const {storage} = configureAuthAndStorage() || {};
  const context = {config, serviceAccountId, storage};
  const client = await apolloClient(graphQlUrl, context, apolloClientCache);

  await onInit({config, client, storage});

  const rootNode = document.getElementById(rootId);
  const root = createRoot(rootNode);

  return root.render(
    <AppRoot config={config} client={client} storage={storage}>
      {rootComponent}
    </AppRoot>,
  );
};
