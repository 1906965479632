import {pathOr} from 'ramda';

import {noop} from '@renofi/utilities';

import {SCHEDULE_INSURANCE_COVERAGE_PROOF_REQUEST} from '../mutations';

import useMutation from './useMutation';

export default function useScheduleInsuranceCoverageProofRequest({
  onCompleted = noop,
} = {}) {
  const [scheduleInsuranceCoverageProofRequest, {data, loading, error}] =
    useMutation(SCHEDULE_INSURANCE_COVERAGE_PROOF_REQUEST, {
      onCompleted,
    });
  const response = pathOr(null, ['insuranceReview'], data);

  return {scheduleInsuranceCoverageProofRequest, response, loading, error};
}
