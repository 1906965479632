import useToggledQuery from '../../useToggledQuery';
import {PREVIEW_APPRAISAL_PACKAGE} from '../../queries';

export default function usePreviewAppraisalPackage({
  fetchPolicy = 'cache-first',
  lazy = false,
  ...options
} = {}) {
  const response = useToggledQuery(PREVIEW_APPRAISAL_PACKAGE, {
    lazy,
    fetchPolicy,
    ...options,
  });

  const {data, error, loading, fetch} = response;
  const {previewAppraisalPackage} = data || {};

  return {error, fetch, previewAppraisalPackage, loading};
}
