import {gql} from '@apollo/client';

import {softCreditCheck} from '../../partials';

export default gql`
  query softCreditChecksByProjectId($projectId: ID!, $received: Boolean) {
    softCreditChecksByProjectId(projectId: $projectId, received: $received) {
      ${softCreditCheck}
      creditLiabilityOverrides {
        adjustedMonthlyPayment
        adjustmentReason
        creditLiabilityId
        projectId
        softCreditCheckId
      }
      creditLiabilityProperties {
        id
        realEstateOwnedPropertyId
        additionalCreditLiabilityId
        softCreditCheckId
        softCreditLiabilityId
      }
    }
  }
`;
