import {gql} from '@apollo/client';

import {partials} from '@renofi/api';

import {
  appraisalRecentTransactions,
  appraisalRenovationDetails,
  contractor,
  documentWithAllProps,
  employmentHistory,
  smsConsentRenovationProgress,
  certificateAcknowledgement,
  advisorFinalCallSchedule,
  productDetailsConfirmation,
  borrowersAndPropertyInformation,
  personalInfoAndResidency,
  incomeSource,
  payStatementInformation,
  assetDetails,
  additionalDocumentRequest,
  softCreditCheck,
  subjectProperty,
  additionalProperty,
  soldProperty,
} from '../../partials';

export default gql`
  query projectTasks($projectId: ID!, $facet: Facet, $status: [TaskStatus!], $taskType: TaskType) {
    projectTasks(projectId: $projectId, facet: $facet,  status: $status, taskType: $taskType) {
      id
      additionalDocumentRequest {
        ${additionalDocumentRequest}
      }
      additionalExplanation
      additionalRequestMessage
      allDocumentsAccepted
      anyDocumentRejected
      allDocumentsReviewed
      appraisalComparableSales {
        id
        createdAt
        updatedAt
        urls
      }
      appraisalPropertyDetails {
        id
        additionalInformation
        additionalInformationPresent
        basementSquareFootage
        basementState
        bedroomsInBasement
        bedroomsTotal
        city
        coolingTypes
        county
        createdAt
        fireplaceTypes
        fullBathroomsInBasement
        fullBathroomsTotal
        garagesAttached
        garagesDetached
        halfBathroomsInBasement
        halfBathroomsTotal
        heatingTypes
        lat
        lng
        mapPhotoUrl
        noteForAppraiser
        noteForAppraiserPresent
        previousRenovationsDescription
        previousRenovationsDescriptionPresent
        propertyType
        propertyTypeOther
        rentalIncome
        rentalIncomeCollectedOnPrimaryResidence
        state
        streetAddressOne
        streetAddressTwo
        streetViewPhotoUrl
        totalSquareFootage
        updatedAt
        yearBuilt
        yearPurchased
        zipCode
      }
      governmentIssuedIds {
        id
        taskId
        idType
        idNumber
        issuingCountry
        issuingState
        issueDate
        expirationDate
        borrowerRole
      }
      appraisalPropertyPhotos {
        ${partials.appraisalPropertyPhoto}
      }
      appraisalRecentTransactions {
        ${appraisalRecentTransactions}
      }
      appraisalRenovationDetails {
        ${appraisalRenovationDetails}
      }
      assetDetails {
        ${assetDetails}
      }
      canHaveDocument
      completedAt
      createdAt
      contractor {
        ${contractor}
      }
      contractorInformations {
        ${contractor}
      }
      dataSubmittedAt
      details
      documents {
        ${documentWithAllProps}
        addedById
        acceptedById
        passwordProtectedFile
        rejectedById
        addedBy {
          id
          fullName
          firstName
          lastName
        }
        acceptedBy {
          id
          firstName
          lastName
        }
        rejectedBy {
          id
          firstName
          lastName
        }
      }
      employmentHistories {
        ${employmentHistory}
      }
      facet
      incomeSources {
        ${incomeSource}
      }
      informationKind
      payStatementInformations {
        ${payStatementInformation}
      }
      requirements
      smsConsentRenovationProgress {
        ${smsConsentRenovationProgress}
      }
      productDetailsConfirmation {
        ${productDetailsConfirmation}
      }
      personalInfoAndResidency {
        ${personalInfoAndResidency}
      }
      realEstateOwned {
        additionalProperties {
          ${additionalProperty}
        }
        additionalPropertyNumber
        additionalPropertyPresent
        id
        soldProperties {
          ${soldProperty}
        }
        soldPropertyNumber
        soldPropertyPresent
        subjectProperty {
          ${subjectProperty}
        }
        taskId
      }
      identityVerifications {
        borrowerRole
        status
      }
      certificateAcknowledgement {
        ${certificateAcknowledgement}
      }
      advisorFinalCallSchedule {
        ${advisorFinalCallSchedule}
      }
      borrowerFinishedAt
      borrowersAndPropertyInformation{
        ${borrowersAndPropertyInformation}
      }
      softCreditCheck {
        ${softCreditCheck}
      }
      status
      submittedAt
      taskType
      title
    }
  }
`;
